import axios from 'axios';
import getConfig, { Env } from '../aws_exports'

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

export const completeRegistration = async (requestRegistration: any, env: string) => {
  const targetEndpoint = `${awsExports.registration.registrationCompleteEndpoint}?env=${env}`;
  const responseData = await axios({
    method: 'POST',
    url: targetEndpoint,
    data: JSON.stringify(requestRegistration),
    headers: {
      'X-Api-Key': `${awsExports.registration.apiKey}`,
    },
  });
  return responseData?.data;
};
export default completeRegistration;