import React, { useState, useEffect } from 'react';
import { SAText, SAInput, SAUXTheme, SAIcons } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

const usernameMaxLenght = 40;
const usernameRegex =/^[\w,-@]{5,40}$/;
const usernameAllowedCharacters =/[\w,-@]/;
const allowedSpecialCharacters = "*.!@$%^&(){}[]:;<>,.?/~_+-=|";
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,60}$/;
const regexKeyDown = new RegExp('[a-zA-Z0-9*.!@$%^&(){}\\[\\]:;<>,.?/~_+-=|\\\\]', '');
const pwdMismatch="Password and Confirm Password do not match";
const pwdFormat=`Password must be at least 12 characters and include one of each:
1) Upper case character\n2) Lower case character
3) One Number\n4) One special character ${allowedSpecialCharacters}`;
const usernameFormat=`Username is required, at least 5 characters lenght and the only acceptable special characters are '_' '-', '.' '@' ','`;


interface RegistrationUsernameProps {
  defaultUsername: string;
  handleUpdateLogin: any,
  handleUpdateValid: any
}
const UsernamePwdContainer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        flex-flow: column wrap;
        justify-content: center;
      }
    `;
  }}
`;
const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  justify-content: start;
`;
const preventInvalidCharacters = (evt: any)=> {
  if (!evt.key.match(regexKeyDown)) evt.preventDefault();
};
const preventInvalidUsername = (evt: any)=> {
  if (!evt.key.match(usernameAllowedCharacters)) evt.preventDefault();
};
const UsernameLabelWrapper = styled.div`
  width: 22%;
`;
const UsernameInputWrapper = styled.div`
  width: 48%;
`;
const UsernameInputValidationWrapper = styled.div`
  width: 30%;
  text-align: left;
  padding: 0 0 0 15px;
  white-space: pre-line;
`;
const TextSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-top: ${theme.size.spacer.large};
      margin-bottom: ${theme.size.spacer.large};
    `;
  }}
`;

export const RegistrationUsername = ({defaultUsername, handleUpdateLogin, handleUpdateValid}: RegistrationUsernameProps) => {
  const appendEmailAsDefault=defaultUsername?.length <= usernameMaxLenght;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [username, setUsername] = useState<string>(appendEmailAsDefault ? defaultUsername: "");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("");
  /**
   * Error State control
   */
  const [passwordMismatchError, setPasswordMismatchError] = useState<boolean>(false);
  const [passwordFormatError, setPasswordFormatError] = useState<boolean>(false);
  const [isUsernameValid, setUsernameValid] = useState<boolean>(true);
  /**
   * Form State control
   */
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordConfirmTouched, setPasswordConfirmTouched] = useState(false);
  useEffect(() => {
    handleUpdateLogin("username", username);
  }, []);
  return (
    <>
      <TextSpacer>
        <SAText
          text="Username and Password"
          type="heading-2"
          colorVariant="primary"
          alignment="left"
        />
      </TextSpacer>
      <UsernamePwdContainer>
        <InputWrapper>
          <UsernameLabelWrapper>
            <SAText text="Username" type="heading-4" alignment="left" />
          </UsernameLabelWrapper>
          <UsernameInputWrapper>
            <SAInput
                id="sign-up-user-name-input"
                test-attr="sign-up-user-name-input"
                maxLength={usernameMaxLenght}
                value={username}
                type="text"
                fullWidth
                onChange={(evt)=>{
                  setUsername(evt.target.value);
                }}
                onKeyDown={preventInvalidUsername}
                onBlur={()=>{
                  setUsernameValid(!!username.match(usernameRegex));
                  const nextPWdStatus = !!username.match(usernameRegex);
                  setUsernameValid(nextPWdStatus);
                  handleUpdateValid(nextPWdStatus && !passwordMismatchError && !passwordFormatError);
                  handleUpdateLogin("username", username);
                }}
                hideLabel
              />
          </UsernameInputWrapper>
          {
            !isUsernameValid &&
            <UsernameInputValidationWrapper>
              <SAText
                text={usernameFormat}
                type="standard"
                colorVariant="alert"
                alignment="left"
              />
            </UsernameInputValidationWrapper>
          }
        </InputWrapper>
        <InputWrapper>
          <UsernameLabelWrapper>
            <SAText text="Password" type="heading-4" alignment="left" />
          </UsernameLabelWrapper>
          <UsernameInputWrapper>
            <SAInput
              data-private
              id="sign-up-password-input"
              test-attr="sign-up-password-input"
              type={showPassword ? 'text' : 'password'}
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              fullWidth
              onKeyDown={preventInvalidCharacters}
              icon={SAIcons.eye}
              onClickIcon={() => setShowPassword(!showPassword)}
              error={(passwordFormatError || passwordMismatchError)}
              onBlur={()=> {
                setPasswordTouched(true);
                const nextPWdStatus = !passwordValue.match(regexPassword);
                setPasswordFormatError(nextPWdStatus);
                handleUpdateValid(!nextPWdStatus && !passwordMismatchError && isUsernameValid);
                if (passwordConfirmTouched || passwordTouched) {
                  const nextPWdMismatchStatus = passwordValue!==passwordConfirmValue;
                  setPasswordMismatchError(nextPWdMismatchStatus);
                }
              }}
              hideLabel
            />
          </UsernameInputWrapper>
          {
            passwordFormatError &&
            <UsernameInputValidationWrapper>
              <SAText
                text={pwdFormat}
                type="standard"
                colorVariant="alert"
              />
            </UsernameInputValidationWrapper>
          }
        </InputWrapper>
        <InputWrapper>
          <UsernameLabelWrapper>
            <SAText text="Confirm Password" type="heading-4" alignment="left" />
          </UsernameLabelWrapper>
          <UsernameInputWrapper>
            <SAInput
              data-private
              id="sign-up-password-input-confirm-pass"
              test-attr="sign-up-password-input-confirm-pass"
              fullWidth
              hideLabel
              type={showPasswordConfirm ? 'text' : 'password'}
              value={passwordConfirmValue}
              onChange={e => setPasswordConfirmValue(e.target.value)}
              icon={SAIcons.eye}
              onKeyDown={preventInvalidCharacters}
              onClickIcon={() => setShowPasswordConfirm(!showPasswordConfirm)}
              error={passwordMismatchError}
              onBlur={()=>{
                setPasswordConfirmTouched(true);
                const nextPWdStatus = passwordValue!==passwordConfirmValue;
                if (passwordConfirmTouched || passwordTouched) {
                  setPasswordMismatchError(nextPWdStatus);
                }
                handleUpdateValid(!nextPWdStatus && !passwordFormatError && isUsernameValid);
                handleUpdateLogin("password", passwordValue);
              }}
            />
          </UsernameInputWrapper>
          {
            passwordMismatchError &&
            <UsernameInputValidationWrapper>
              <SAText
                text={pwdMismatch}
                type="standard"
                colorVariant="alert"
                alignment="left"
              />
            </UsernameInputValidationWrapper>
          }
        </InputWrapper>
      </UsernamePwdContainer>
    </>
  );
}
export default RegistrationUsername;