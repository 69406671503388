import React, {useState} from 'react';
import { SAText, SAInput, SASelect } from '@saux/design-system-react';
import { MainElementContainer
  ,ElementContainer
  ,LabelWrapper
  ,InputWrapper
  ,ErrorWrapper } from './Common'

interface QuestionSetProp {
  qsNumber: number,
  availableOptions: any[],
  handleQuestionSelect: any,
  handleAnswerUpdate: any
};

export const QuestionSet: Function = ({qsNumber, availableOptions, handleQuestionSelect, handleAnswerUpdate}: QuestionSetProp) => {
  const [answerText, setAnswerText] = useState("");
  const [invalidAnswer, setInvalidAnswer] = useState(false);
  const [invalidQuestion, setInvalidQuestion] = useState(false);
  const invalidAnswerText = "The answer must have at least 4 characters";
  const selectQuestionText = 'Please select a question from the list.';
  const minSize = 4;
  return (
  <>
  <MainElementContainer>
      <ElementContainer>
        <LabelWrapper>
          <SAText text={`Question #${qsNumber}`} type="heading-4" alignment="left" />
        </LabelWrapper>
        <InputWrapper>
          <SASelect
            fullWidth
            id="sign-up-security-question-1-choice"
            test-attr="sign-up-security-question-1-choice"
            options={availableOptions}
            onChange={(evt:any) => {
              handleQuestionSelect(evt.target.value, qsNumber, answerText);
              if (evt.target.selectedIndex === 0) {
                setInvalidQuestion(true);
              } else if (invalidQuestion) {
                setInvalidQuestion(false);
              }
            }}
          />
        </InputWrapper>
        {
          invalidQuestion &&
          <ErrorWrapper>
            <SAText
              text={selectQuestionText}
              type="standard"
              colorVariant="alert"
              alignment="left"
            />
          </ErrorWrapper>
        }
      </ElementContainer>
      <ElementContainer>
        <LabelWrapper>
          <SAText text={`Answer #${qsNumber}`} type="heading-4" alignment="left" />
        </LabelWrapper>
        <InputWrapper>
          <SAInput
            data-private
            id="sign-up-security-question-input"
            test-attr="sign-up-security-question-answer"
            type="text"
            value={answerText}
            onChange={(evt)=>{setAnswerText(evt.target?.value)}}
            fullWidth
            hideLabel
            maxLength={80}
            onFocus={()=>{setInvalidAnswer(false);}}
            onBlur={() => {
              const trimmed=answerText.trim();
              if (trimmed.length >= minSize) {
                setAnswerText((ans)=>{return ans.trim();});
                handleAnswerUpdate(trimmed, qsNumber);
              } else {
                setInvalidAnswer(true);
              }
            }}
          />
        </InputWrapper>
        {
          invalidAnswer &&
          <ErrorWrapper>
            <SAText
              text={invalidAnswerText}
              type="standard"
              colorVariant="alert"
              alignment="left"
            />
          </ErrorWrapper>
        }
      </ElementContainer>
    </MainElementContainer>
  </>
  );
};
export default QuestionSet;
