/* eslint-disable consistent-return */
import React, { useState } from 'react';
import axios from 'axios';
import { FirstEmailCheckModal } from './FirstEmailCheckModal';
import getConfig, { Env } from '../../aws_exports';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface FirstEmailCheckModalDataContainerProps {
  setInitialEmailCheckView: Function;
  setSecondEmailCheckView: Function;
  setMultipleAccountsAssociatedView: Function;
  setMoreInfoNeededCL: Function;
  setMoreInfoNeeded: Function;
  setVerificationView: Function;
  accountType: string;
}

interface CallCheckInputtedEmailProps {
  setLoading: Function;
  setAlreadyActiveEID: Function;
  setSecondEmailCheckView: Function;
  setInitialEmailCheckView: Function;
  setVerificationView: Function;
  setMoreInfoNeededCL: Function;
  setMoreInfoNeeded: Function;
  setMultipleAccountsAssociatedView: Function;
  email: string;
  accountType: string;
  setError: Function;
}

export const callCheckInputtedEmail = async ({
  email,
  setLoading,
  setAlreadyActiveEID,
  setSecondEmailCheckView,
  setInitialEmailCheckView,
  setVerificationView,
  setMoreInfoNeededCL,
  setMoreInfoNeeded,
  setMultipleAccountsAssociatedView,
  accountType,
  setError,
}: CallCheckInputtedEmailProps) => {
  setLoading(true);
  sessionStorage.setItem('inputtedEmail', email.toLowerCase() ?? '-');
  const postData = {
    email: `${email.toLowerCase()}`,
    accountType,
  };
  if (email !== '') {
    await axios({
      method: 'post',
      url: awsExports.gateway_endpoints.getUserByEmail,
      data: postData,
    })
      .then(async (response) => {
        if (response.data) {
          // EmailCheck API call succeeded
          response.data = JSON.parse(atob(response.data));
          if (response.data?.emailIsVerified === false) {
            setLoading(false);
            return setError(true);
          }
          if (
            response.data.multipleUserAttributes &&
            response.data.multipleUserAttributes.accountInfo &&
            response.data.multipleUserAttributes.accountInfo.length > 0 &&
            response.data.accountNumbers &&
            response.data.accountNumbers.length > 0
          ) {
            // Email has multiple accounts associated and 'accountNumbers' array is populated

            /*
             * Create an array of eligible unregistered accounts using
             * the 'response.data.multipleUserAttributes.accountInfo' array
             * and the 'response.data.accountNumbers' array
             * Only keep the objects in which the account number does not exist in both
             * NOTE: this array contains POSSIBLY unregistered accounts
             */
            const determineUnregistered =
              response.data.multipleUserAttributes.accountInfo.filter(
                (account: { account_number: string }) =>
                  !response.data.accountNumbers.includes(
                    account.account_number,
                  ),
              );

            if (
              determineUnregistered.length > 0 &&
              response.data.associatedSameEmailUsers &&
              response.data.associatedSameEmailUsers.length <
                response.data.multipleUserAttributes.accountInfo.length
            ) {
              // There is at least one unregistered account from the above created array
              sessionStorage.setItem(
                'registeredAccountsWithUserName',
                JSON.stringify({
                  associatedUserName: response.data.associatedUserName,
                  accountNumbers: response.data.accountNumbers ?? [],
                }),
              );
              sessionStorage.setItem(
                'returnedPolicyAttributesCollection',
                JSON.stringify(
                  response.data.multipleUserAttributes.policyInfo,
                ) ?? '-',
              );
              sessionStorage.setItem(
                'returnedAccountAttributesCollection',
                JSON.stringify(
                  response.data.multipleUserAttributes.accountInfo,
                ) ?? '-',
              );
              setLoading(false);
              setInitialEmailCheckView(false);
              setMultipleAccountsAssociatedView(true);
            } else if (
              response.data.associatedSameEmailUsers.length >=
                response.data.multipleUserAttributes.accountInfo.length &&
              response.data.multipleUserAttributes.accountInfo.length > 1
            ) {
              // This email has multiple associated accounts and (what else)?
              sessionStorage.setItem(
                'alreadyRegisteredAccounts',
                JSON.stringify({
                  associatedUserName:
                    response.data.associatedSameEmailUsers ?? [],
                }),
              );
              sessionStorage.setItem(
                'returnedPolicyAttributesCollection',
                JSON.stringify(
                  response.data.multipleUserAttributes.policyInfo,
                ) ?? '-',
              );
              sessionStorage.setItem(
                'returnedAccountAttributesCollection',
                JSON.stringify(
                  response.data.multipleUserAttributes.accountInfo,
                ) ?? '-',
              );
              setLoading(false);
              setInitialEmailCheckView(false);
              setSecondEmailCheckView(true);
            } else {
              // There are not any unregistered accounts
              setLoading(false);
              setAlreadyActiveEID(true);
            }
          } else if (
            response.data.associatedUserName &&
            response.data.associatedUserName !== '' &&
            response.data.registeredWithDifferentUsername &&
            (response.data.multipleUserAttributes.accountInfo.length <=
              response.data.associatedSameEmailUsers.length ||
              response.data.userAttributes.userAttributesFromDynamo.length <=
                response.data.associatedSameEmailUsers.length)
          ) {
            /*
             * There is an 'associatedUserName' and there are (more or equal)
             * 'associatedSameEmailUsers' than either 'userAttributesFromDynamo'
             * or 'accountInfo'
             */
            sessionStorage.setItem(
              'returnedPolicyAttributesCollection',
              JSON.stringify(
                response.data.multipleUserAttributes.policyInfo.length !== 0
                  ? response.data.multipleUserAttributes.policyInfo
                  : response.data.userAttributes.mainPolicyNum,
              ) ?? '-',
            );
            sessionStorage.setItem(
              'returnedAccountAttributesCollection',
              JSON.stringify(
                response.data.multipleUserAttributes.accountInfo.length !== 0
                  ? response.data.multipleUserAttributes.accountInfo
                  : response.data.userAttributes.userAttributesFromDynamo,
              ) ?? '-',
            );
            sessionStorage.setItem(
              'alreadyRegisteredAccounts',
              JSON.stringify({
                associatedUserName:
                  response.data.associatedSameEmailUsers ?? [],
              }),
            );
            setLoading(false);
            setInitialEmailCheckView(false);
            setSecondEmailCheckView(true);
          } else if (
            response.data.alreadyActiveInEID &&
            response.data.multipleUserAttributes.accountInfo.length <=
              response.data.associatedSameEmailUsers.length
          ) {
            // All accounts (1 or more) are already registered
            setLoading(false);
            setAlreadyActiveEID(true);
          } else if (
            response.data.userAttributes &&
            response.data.userAttributes.userAttributesFromDynamo &&
            response.data.userAttributes.userAttributesFromDynamo[0] &&
            response.data.userAttributes.userAttributesFromDynamo[0] !== {}
          ) {
            // There is at least one account associated with the email
            sessionStorage.setItem(
              'returnedGivenName',
              response.data.userAttributes.userAttributesFromDynamo[0]
                .first_name ?? '-',
            );
            sessionStorage.setItem(
              'returnedAccountAttributesCollection',
              JSON.stringify(
                response.data.userAttributes.userAttributesFromDynamo,
              ) ?? '-',
            );
            if (
              response.data.userAttributes &&
              response.data.userAttributes.mainPolicyNum &&
              response.data.userAttributes.mainPolicyNum[0] &&
              response.data.userAttributes.mainPolicyNum[0] !== {}
            ) {
              // There is exactly one account associated with the email
              sessionStorage.setItem(
                'returnedPolicyAttributesCollection',
                JSON.stringify(response.data.userAttributes.mainPolicyNum) ??
                  '-',
              );
            } else if (
              response.data.multipleUserAttributes &&
              response.data.multipleUserAttributes.policyInfo &&
              response.data.multipleUserAttributes.policyInfo[0]
            ) {
              // There is more than one account associated with the email
              sessionStorage.setItem(
                'returnedPolicyAttributesCollection',
                JSON.stringify(
                  response.data.multipleUserAttributes.policyInfo,
                ) ?? '-',
              );
            }

            // Create an object with key of 'key1' and value of an array of account number(s) associated with the email
            const secondCallPostData = {
              key1: response.data.userAttributes.userAttributesFromDynamo.map(
                (dynamoData: any) => dynamoData.account_number,
              ),
            };
            // Send the account numbers associated with this email to determine which ones are registered with EID
            await axios({
              method: 'post',
              url: awsExports.gateway_endpoints.getLoginFromAccNum,
              data: secondCallPostData,
            })
              .then((res) => {
                if (res.data) {
                  res.data = JSON.parse(atob(res.data));
                  if (
                    res.data.multipleUserAttributes &&
                    res.data.multipleUserAttributes
                      .accountInfoByFoundAccountNumber &&
                    res.data.returnedLogins &&
                    res.data.multipleUserAttributes
                      .accountInfoByFoundAccountNumber.length >
                      res.data.returnedLogins.length
                  ) {
                    // There is at least 1 unregisterd account and multiple account numbers were sent to the api
                    const accountNumbersCollection = [];
                    for (
                      let i = 0;
                      i <
                      res.data.multipleUserAttributes
                        .accountInfoByFoundAccountNumber.length;
                      i += 1
                    ) {
                      // Looping through all account objects from the response data
                      if (
                        res.data.multipleUserAttributes
                          .accountInfoByFoundAccountNumber[i].alreadyActiveInEID
                      ) {
                        // The account has already been registered with EID
                        accountNumbersCollection.push(
                          res.data.multipleUserAttributes
                            .accountInfoByFoundAccountNumber[i].accountNumber ??
                            '',
                        );
                      }
                    }

                    sessionStorage.setItem(
                      'registeredAccountsWithUserName',
                      JSON.stringify({
                        associatedUserName:
                          res.data.returnedLogins.length !== 0
                            ? res.data.returnedLogins[0][0]
                            : 'Not Registered',
                        accountNumbers: accountNumbersCollection,
                      }),
                    );
                    setLoading(false);
                    setInitialEmailCheckView(false);
                    setMultipleAccountsAssociatedView(true);
                  } else if (
                    res.data.alreadyActiveInEID &&
                    res.data.associatedUserName &&
                    res.data.associatedUserName !== []
                  ) {
                    // There was one account number sent to the api and that account existed and was registered with EID
                    sessionStorage.setItem(
                      'alreadyRegisteredAccounts',
                      JSON.stringify({
                        associatedUserName: [
                          {
                            Login: res.data.associatedUserName[0],
                          },
                        ],
                      }),
                    );
                    setLoading(false);
                    setInitialEmailCheckView(false);
                    setSecondEmailCheckView(true);
                  } else {
                    // Default conglomerate of cases
                    sessionStorage.setItem(
                      'returnedAccountAttributesCollection',
                      JSON.stringify(
                        response.data.userAttributes.userAttributesFromDynamo,
                      ) ?? '-',
                    );
                    setLoading(false);
                    setInitialEmailCheckView(false);
                    setVerificationView(true);
                  }
                } else {
                  setLoading(false);
                  if (accountType === 'Business') setMoreInfoNeededCL(true);
                  else setMoreInfoNeeded(true);
                  setInitialEmailCheckView(false);
                }
              })
              .catch(() => {
                setLoading(false);
                if (accountType === 'Business') setMoreInfoNeededCL(true);
                else setMoreInfoNeeded(true);
                setInitialEmailCheckView(false);
              });
          } else {
            setLoading(false);
            if (accountType === 'Business') setMoreInfoNeededCL(true);
            else setMoreInfoNeeded(true);
            setInitialEmailCheckView(false);
          }
        } else {
          setLoading(false);
          if (accountType === 'Business') setMoreInfoNeededCL(true);
          else setMoreInfoNeeded(true);
          setInitialEmailCheckView(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return console.log(err);
      });
  }
};

const FirstEmailCheckModalDataContainer = ({
  setInitialEmailCheckView,
  setSecondEmailCheckView,
  setMultipleAccountsAssociatedView,
  setVerificationView,
  setMoreInfoNeededCL,
  setMoreInfoNeeded,
  accountType,
}: FirstEmailCheckModalDataContainerProps) => {
  const [alreadyActiveEID, setAlreadyActiveEID] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const checkInputtedEmail = (email: string) => {
    if (email === '' || !/\S+@\S+\.\S+/.test(email)) return setError(true);
    return callCheckInputtedEmail({
      email,
      setLoading,
      setAlreadyActiveEID,
      setSecondEmailCheckView,
      setInitialEmailCheckView,
      setVerificationView,
      setMoreInfoNeededCL,
      setMoreInfoNeeded,
      setMultipleAccountsAssociatedView,
      accountType,
      setError,
    });
  };

  return (
    <FirstEmailCheckModal
      checkInputtedEmail={checkInputtedEmail}
      loading={loading}
      error={error}
      setError={setError}
      alreadyActiveEID={alreadyActiveEID}
      setAlreadyActiveEID={setAlreadyActiveEID}
      accountType={accountType}
    />
  );
};

export default FirstEmailCheckModalDataContainer;
