import React from 'react';
import { SetPasswordModal } from './setPasswordModal';
import { SetQuestionsModal } from './setQuestionsModal';
import { ConfirmUserInfoModal } from './confirmUserInfoModal';
import { FinalRedirectModal } from './finalRedirectModal';

interface SignUpModalProps {
  displayFirstName: string | undefined;
  foundUserName: string | undefined;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  setSignedUpUserInfo: Function;
  registerUser: Function;
  loading: boolean;
  showFinalRedirectModal: boolean;
  setShowConfirmUserInfoModal: Function;
  checkUsernameAndPassword: Function;
  showConfirmUserInfoModal: boolean;
  setTakenUserName: Function;
  takenUserName: boolean;
  showSetPasswordModal: boolean;
  showSetQuestionsModal: boolean;
  setShowSetQuestionsModal: Function;
  error: boolean;
  setError: Function;
  invalidUsername: boolean;
  setInvalidUsername: Function;
  passwordHasCapital: boolean;
  accountType: string;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  passwordError: boolean;
  setPasswordError: Function;
  confirmedPassword: string;
  setConfirmedPassword: Function;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
  showEmailAsDefaultUsername: boolean;
}

export interface SignedUpUserInfo {
  userName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  accountNumber?: string;
  dateOfBirth?: string;
  password?: string;
  phone?: string;
  firstSecurityQuestion?: string;
  firstSecurityQuestionAnswer?: string;
  secondSecurityQuestion?: string;
  secondSecurityQuestionAnswer?: string;
  thirdSecurityQuestion?: string;
  thirdSecurityQuestionAnswer?: string;
}

export const SignUpModal = ({
  displayFirstName,
  foundUserName,
  signedUpUserInfo,
  setSignedUpUserInfo,
  loading,
  registerUser,
  showFinalRedirectModal,
  setShowConfirmUserInfoModal,
  showConfirmUserInfoModal,
  checkUsernameAndPassword,
  setTakenUserName,
  takenUserName,
  showSetPasswordModal,
  showSetQuestionsModal,
  setShowSetQuestionsModal,
  error,
  setError,
  invalidUsername,
  setInvalidUsername,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  passwordError,
  setPasswordError,
  accountType,
  confirmedPassword,
  setConfirmedPassword,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
  showEmailAsDefaultUsername,
}: SignUpModalProps) => {
  if (showSetPasswordModal) {
    return (
      <SetPasswordModal
        displayFirstName={displayFirstName}
        foundUserName={foundUserName}
        signedUpUserInfo={signedUpUserInfo}
        setSignedUpUserInfo={setSignedUpUserInfo}
        checkUsernameAndPassword={checkUsernameAndPassword}
        setTakenUserName={setTakenUserName}
        takenUserName={takenUserName}
        loading={loading}
        error={error}
        setError={setError}
        invalidUsername={invalidUsername}
        setInvalidUsername={setInvalidUsername}
        passwordHasCapital={passwordHasCapital}
        passwordHasNumber={passwordHasNumber}
        passwordHasLowercase={passwordHasLowercase}
        passwordHasSpecialChar={passwordHasSpecialChar}
        confirmedPassword={confirmedPassword}
        setConfirmedPassword={setConfirmedPassword}
        passwordsDoNotMatch={passwordsDoNotMatch}
        setPasswordsDoNotMatch={setPasswordsDoNotMatch}
        showEmailAsDefaultUsername={showEmailAsDefaultUsername}
      />
    );
  }
  if (showSetQuestionsModal) {
    return (
      <SetQuestionsModal
        displayFirstName={displayFirstName}
        signedUpUserInfo={signedUpUserInfo}
        setSignedUpUserInfo={setSignedUpUserInfo}
        setShowSetQuestionsModal={setShowSetQuestionsModal}
        setShowConfirmUserInfoModal={setShowConfirmUserInfoModal}
      />
    );
  }
  if (showConfirmUserInfoModal) {
    return (
      <ConfirmUserInfoModal
        loading={loading}
        invalidUsername={invalidUsername}
        takenUserName={takenUserName}
        displayFirstName={displayFirstName}
        foundUserName={foundUserName}
        signedUpUserInfo={signedUpUserInfo}
        setSignedUpUserInfo={setSignedUpUserInfo}
        registerUser={registerUser}
        accountType={accountType}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
        setTakenUserName={setTakenUserName}
        setInvalidUsername={setInvalidUsername}
        passwordHasCapital={passwordHasCapital}
        passwordHasNumber={passwordHasNumber}
        passwordHasLowercase={passwordHasLowercase}
        passwordHasSpecialChar={passwordHasSpecialChar}
        confirmedPassword={confirmedPassword}
        setConfirmedPassword={setConfirmedPassword}
        passwordsDoNotMatch={passwordsDoNotMatch}
        setPasswordsDoNotMatch={setPasswordsDoNotMatch}
      />
    );
  }
  if (showFinalRedirectModal) {
    return <FinalRedirectModal displayFirstName={displayFirstName} />;
  }
  return <>Welcome to State Auto</>;
};

export default SignUpModal;
