import React from 'react';
import styled from 'styled-components';
import { SAText, SAInput, SAUXTheme } from '@saux/design-system-react';
import { TextSpacer } from '../signUpModal/SignUpModalStyles';
import * as CONSTANTS from './RegistrationPInfoText';
import customBase from '../../util/global.theme';
import { IPersonalInformation } from '../../data/RegistrationData';
import { RegistrationPhoneDOB } from './RegistrationPhoneDOB';

interface RegistrationPInfoProps {
  registerUser: IPersonalInformation,
  businessType: string,
  handleInformationUpdate: any,
  handleUpdateValidity: Function
}
const NameRow = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      padding: 0px 0px ${theme.size.spacer.medium} 0px;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        flex-direction: column;
        justify-content: center;
        padding: 0px;
      }
    `;
  }}
`;
const InputWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      width: 48%;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        width: 100%;
      }
    `;
  }}
`;
const EmailWrap = styled.div`
  width: 50%;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
  width: 100%;
  }
`;

export const RegistrationPInfo =
({registerUser, businessType, handleInformationUpdate, handleUpdateValidity}: RegistrationPInfoProps) => {
  const typeName = businessType && businessType.toLowerCase() === 'cl' ? CONSTANTS.NAME_LABEL_CL : CONSTANTS.NAME_LABEL_PL;
  return (
    <>
      <TextSpacer>
        <SAText
          text={CONSTANTS.PERSONAL_INFO}
          type="heading-2"
          colorVariant="primary"
          alignment="left"
        />
      </TextSpacer>
      <NameRow>
        <InputWrapper>
          <SAText
            text={typeName}
            type="heading-4"
            alignment="left"
          />
        </InputWrapper>
      </NameRow>
      <NameRow>
        <InputWrapper>
          <SAInput
            id="sign-up-confirm-first-name-input"
            hint={CONSTANTS.PINFO_FIRST_NAME}
            type="text"
            fullWidth
            defaultValue={registerUser?.firstName}
            disabled
            className='data-disabled'
            hideLabel
            backgroundColor='rgba(204, 204, 204, 0.2)'
          />
        </InputWrapper>
        <InputWrapper>
          <SAInput
            id="sign-up-confirm-last-name-input"
            type="text"
            hint={CONSTANTS.PINFO_LAST_NAME}
            fullWidth
            defaultValue={registerUser?.lastName}
            disabled
            hideLabel
            backgroundColor='rgba(204, 204, 204, 0.2)'
          />
        </InputWrapper>
      </NameRow>
      <NameRow>
        <EmailWrap>
          <SAInput
            id="sign-up-confirm-last-name-input"
            type="text"
            hint={CONSTANTS.PINFO_EMAIL}
            defaultValue={registerUser?.email}
            style={{ backgroundColor: 'rgba(204, 204, 204, 0.2)' }}
            hideLabel
            disabled
            fullWidth
          />
        </EmailWrap>
        <RegistrationPhoneDOB
          registerUser={registerUser}
          businessType={businessType}
          onChangeDOBPhone={handleInformationUpdate}
          updateValidity={handleUpdateValidity}
        />
      </NameRow>
    </>
  )
}

export default RegistrationPInfo;