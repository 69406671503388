import React from 'react';
import { SAText } from '@saux/design-system-react';
import {
  ContactOptionsHolder,
  InnerTextHolder,
  PopoverModal,
  RedirectButton,
} from '../SignUpModalStyles';
import getConfig, { Env } from '../../../aws_exports';
import useIsResponsive from '../../../util/useIsResponsive';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface FinalRedirectModalProps {
  displayFirstName: string | undefined;
}

export const FinalRedirectModal = ({
  displayFirstName,
}: FinalRedirectModalProps) => {
  const isResponsive = useIsResponsive();

  setTimeout(() => {
    window.open(awsExports.endpoint.customer360, '_self');
  }, 4000);

  return (
    <>
      {!isResponsive && (
        <SAText
          text={`Welcome to State Auto${
            displayFirstName ? `, ${displayFirstName}` : ''
          }!`}
          type="heading-1"
          alignment="center"
        />
      )}
      <PopoverModal>
        <InnerTextHolder>
          <SAText
            text="Thank you for registering with us. Please wait while you are redirected to the log in page. If nothing happens within 5 seconds, please click on the button below"
            type="heading-3"
            alignment="center"
          />
        </InnerTextHolder>
        <ContactOptionsHolder>
          <RedirectButton
            test-attr="final-redirect-button"
            onClick={() => {
              window.open(awsExports.endpoint.customer360, '_self');
              simpleGTMDataLayer({
                event: `Registration-FinalRedirect-click`,
                event_action: 'Redirect To Login Click',
                event_category: 'Registration',
                event_label: 'Registration Final Redirect Click',
                event_category_snowplow: 'Snowplow Registration',
              });
            }}
          >
            REDIRECT
          </RedirectButton>
        </ContactOptionsHolder>
      </PopoverModal>
    </>
  );
};

export default FinalRedirectModal;
