import React from 'react';
import { SAButton, SAInput, SAText } from '@saux/design-system-react';
import {
  ButtonHolder,
  InputHolder,
  VerificationCodeInputHolder,
  RowInputs,
  WideInput,
  NotWideInput,
  DOBWideInput,
  Card,
} from './MoreInfoNeededModalStyles';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface MoreInfoNeededModalProps {
  verifyEnteredInfo: Function;
  loading: boolean;
  userInfo: UserInfo | undefined;
  setUserInfo: Function;
  firstNameError: boolean;
  setFirstNameError: Function;
  lastNameError: boolean;
  setLastNameError: Function;
  dobError: boolean;
  setDobError: Function;
  zipCodeError: boolean;
  setZipCodeError: Function;
}

export interface UserInfo {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  zipCode?: string;
  dateOfBirth?: string;
}

export const MoreInfoNeededModal = ({
  verifyEnteredInfo,
  userInfo,
  setUserInfo,
  loading,
  firstNameError,
  setFirstNameError,
  lastNameError,
  setLastNameError,
  dobError,
  setDobError,
  zipCodeError,
  setZipCodeError,
}: MoreInfoNeededModalProps) => {
  return (
    <Card>
      <SAText
        text="This email is not associated with any account in our records. Please provide more information for us to locate your account."
        type="heading-3"
        alignment="center"
      />
      <VerificationCodeInputHolder>
        <InputHolder>
          <RowInputs>
            <WideInput>
              {!firstNameError ? (
                <SAInput
                  id="more-info-first-name-input"
                  label="First Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.firstName}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      firstName: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-first-name-input"
                  test-attr="more-info-first-name-input-error"
                  label="First Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.firstName}
                  onClick={() => setFirstNameError(false)}
                  hint="Please fill in your first name"
                  error
                  readOnly
                />
              )}
            </WideInput>
            <NotWideInput>
              <SAInput
                id="more-info-middle-name-input"
                label="Middle Name"
                hint="(Optional)"
                type="search"
                autoComplete="off"
                fullWidth
                onChange={(evt) => {
                  setUserInfo({
                    ...userInfo,
                    middleName: evt.target.value,
                  });
                }}
              />
            </NotWideInput>
            <WideInput>
              {!lastNameError ? (
                <SAInput
                  id="more-info-last-name-input"
                  label="Last Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.lastName}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      lastName: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-last-name-input"
                  test-attr="more-info-last-name-input-error"
                  label="Last Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.lastName}
                  onClick={() => setLastNameError(false)}
                  hint="Please fill in your last name"
                  error
                  readOnly
                />
              )}
            </WideInput>
          </RowInputs>
          <RowInputs>
            <DOBWideInput>
              {!dobError ? (
                <SAInput
                  id="more-info-date-of-birth-input"
                  label="Date of Birth"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  maxLength={10}
                  defaultValue={userInfo?.dateOfBirth}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      dateOfBirth: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-date-of-birth-input"
                  test-attr="more-info-dob-input-error"
                  label="Date of Birth"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.dateOfBirth}
                  onClick={() => setDobError(false)}
                  hint="Please fill in your date of birth"
                  error
                  readOnly
                />
              )}
            </DOBWideInput>
          </RowInputs>
          <RowInputs>
            <WideInput>
              {!zipCodeError ? (
                <SAInput
                  id="more-info-zip-code-input"
                  label="Zip Code"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.zipCode}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      zipCode: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-zip-code-input"
                  test-attr="more-info-zip-code-input-error"
                  label="Zip Code"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.zipCode}
                  onClick={() => setZipCodeError(false)}
                  hint="Please fill in your zip code"
                  error
                  readOnly
                />
              )}
            </WideInput>
          </RowInputs>
        </InputHolder>
      </VerificationCodeInputHolder>
      <ButtonHolder>
        {!loading ? (
          <SAButton
            test-attr="continue-more-info-needed-screen"
            onClick={() => {
              verifyEnteredInfo();
              simpleGTMDataLayer({
                event: `Registration-MoreInfoContinueButton-click`,
                event_action: 'Button Click',
                event_category: 'Registration',
                event_label: 'Registration More Info Continue Button Click',
                event_category_snowplow: 'Snowplow Registration',
              });
            }}
            label="CONTINUE"
            variant="primary-large"
            fullWidth
          />
        ) : (
          <SAButton label="CONTINUE" variant="primary-large" fullWidth>
            <SpinnerLight />
          </SAButton>
        )}
      </ButtonHolder>
    </Card>
  );
};

export default MoreInfoNeededModal;
