import React, { useEffect, useState } from 'react';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from '@saux/design-system-react';
import { ReactComponent as WhitePhoneIcon } from '../../assets/images/WhitePhone.svg';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import {
  ButtonHolder,
  Input,
  ErrorInput,
  InputHolder,
  Card,
  TextHolder,
  VerificationCodeInputHolder,
  Backdrop,
  PopoverModal,
  HeaderHolder,
  CloseIcon,
  BottomTextHolder,
  TimerHolder,
  InnerTextHolder,
  ContactButton,
  ContactOptionsHolder,
  IconHolder,
  ContactButtonTextHolder,
  PhoneLink,
  ErrorInputHolder,
} from './VerificationCodeModalStyles';
import { formatMinutes } from '../../util';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface VerificationCodeModalProps {
  setInputtedCode: Function;
  foundUserName: string | null;
  countdownLength?: number | undefined;
  numberOfResends: number;
  setNumberOfResends: Function;
  inputError: boolean;
  setInputError: Function;
  recallVerificationGeneration: Function;
  setLoading: Function;
  loading: boolean;
}

interface ErrorModalProps {
  setError: Function;
}

interface DisplayInputProps {
  testAttr: string;
  isError?: boolean;
  setInputError?: Function;
}

export const ErrorModal: Function = ({ setError }: ErrorModalProps) => {
  return (
    <Backdrop>
      <PopoverModal>
        <HeaderHolder>
          <CloseIcon
            test-attr="exit-verify-popover"
            onClick={() => {
              setError(false);
            }}
          >
            <SAIcon
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
            />
          </CloseIcon>
        </HeaderHolder>
        <InnerTextHolder>
          <SAText
            text="Still did not get the verification code? Check your spam folders and contact us for help."
            type="heading-3"
            alignment="center"
          />
        </InnerTextHolder>
        <ContactOptionsHolder>
          <ContactButton>
            <IconHolder>
              <WhitePhoneIcon />
            </IconHolder>
            <ContactButtonTextHolder>
              <PhoneLink
                href="tel:+18337243577"
                test-attr="phone-link"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: `Registration-PhoneNumberButton-click`,
                    event_action: 'Button Click',
                    event_category: 'Registration',
                    event_label: 'Registration Phone Number Button Click',
                    event_category_snowplow: 'Snowplow Registration',
                  });
                }}
              >
                1 (833) 724-3577
              </PhoneLink>
            </ContactButtonTextHolder>
          </ContactButton>
        </ContactOptionsHolder>
      </PopoverModal>
    </Backdrop>
  );
};

export const DisplayInput: Function = ({
  testAttr,
  isError,
  setInputError,
}: DisplayInputProps) => {
  if (isError && setInputError) {
    return (
      <ErrorInput
        id="input-error-code-boxes"
        test-attr={testAttr}
        name="secret-code"
        className="code-input"
        required
        maxLength={1}
        onClick={() => setInputError(false)}
        type="text"
      />
    );
  }
  return (
    <Input
      test-attr={testAttr}
      name="secret-code"
      className="code-input"
      required
      maxLength={1}
      type="text"
    />
  );
};

export const VerificationCodeModal = ({
  setInputtedCode,
  foundUserName,
  countdownLength,
  numberOfResends,
  setNumberOfResends,
  inputError,
  setInputError,
  recallVerificationGeneration,
  setLoading,
  loading,
}: VerificationCodeModalProps) => {
  const [error, setError] = useState<boolean>(false);
  const [resendSeconds, setResendSeconds] = useState<number>(
    countdownLength ?? 300,
  );
  const device = foundUserName?.includes('@') ? 'email' : 'phone';
  useEffect(() => {
    const elts = document.getElementsByClassName('code-input');
    return Array.from(elts).forEach((elt: Element | any) => {
      elt.addEventListener('keyup', (evt: KeyboardEvent) => {
        if (evt.code === 'Backspace') {
          if (elt.previousElementSibling) {
            elt.previousElementSibling.focus();
          }
        }
        if (elt.value.length === 1) {
          if (elt.nextElementSibling) {
            elt.nextElementSibling.focus();
          }
        }
      });
    });
  }, [error, []]);

  useEffect(() => {
    const timer =
      resendSeconds > 0 &&
      setInterval(() => setResendSeconds(resendSeconds - 1), 1000);
    return () => clearInterval(timer as unknown as number | undefined);
  }, [resendSeconds]);

  const onContinue: Function = async () => {
    setLoading(true);
    let inputtedCode: string = '';
    const inputs: HTMLInputElement[] = document.getElementsByName(
      'secret-code',
    ) as unknown as HTMLInputElement[];
    for (let i = 0; i < inputs.length; i += 1) {
      inputtedCode += inputs[i]?.value?.toString();
    }
    if (inputtedCode !== '' && inputtedCode.length === 6) {
      setInputtedCode(inputtedCode);
    } else setError(true);
  };

  return (
    <>
      {error && <ErrorModal setError={setError} />}
      <Card>
        <TextHolder>
          <SAText
            text={`We have sent a 6-digit verification code to your ${device} ${foundUserName}.`}
            type="standard"
            alignment="center"
            weight="bold"
          />
        </TextHolder>
        <TextHolder>
          <SAText
            text="Please enter the verification code below."
            type="standard"
            alignment="center"
          />
        </TextHolder>
        <VerificationCodeInputHolder>
          {!inputError && (
            <InputHolder>
              <DisplayInput testAttr="input-box-one" />
              <DisplayInput testAttr="input-box-two" />
              <DisplayInput testAttr="input-box-three" />
              <DisplayInput testAttr="input-box-four" />
              <DisplayInput testAttr="input-box-five" />
              <DisplayInput testAttr="input-box-six" />
            </InputHolder>
          )}
          {inputError && (
            <>
              <InputHolder>
                <DisplayInput
                  isError
                  testAttr="input-box-one"
                  setInputError={setInputError}
                />
                <DisplayInput
                  isError
                  testAttr="input-box-two"
                  setInputError={setInputError}
                />
                <DisplayInput
                  isError
                  testAttr="input-box-three"
                  setInputError={setInputError}
                />
                <DisplayInput
                  isError
                  testAttr="input-box-four"
                  setInputError={setInputError}
                />
                <DisplayInput
                  isError
                  testAttr="input-box-five"
                  setInputError={setInputError}
                />
                <DisplayInput
                  isError
                  testAttr="input-box-six"
                  setInputError={setInputError}
                />
              </InputHolder>
              <ErrorInputHolder>
                <SAText
                  text="The code you entered is incorrect. Please try again."
                  type="standard"
                  alignment="center"
                  colorVariant="alert"
                />
              </ErrorInputHolder>
            </>
          )}
        </VerificationCodeInputHolder>
        <ButtonHolder>
          {loading ? (
            <SAButton label="CONTINUE" variant="primary-large" fullWidth>
              <SpinnerLight />
            </SAButton>
          ) : (
            <SAButton
              test-attr="continue-verify-screen"
              onClick={() => {
                onContinue();
                simpleGTMDataLayer({
                  event: `Registration-EnterVerificationCodeContinueButton-click`,
                  event_action: 'Button Click',
                  event_category: 'Registration',
                  event_label:
                    'Registration Enter Verification Code Continue Button Click',
                  event_category_snowplow: 'Snowplow Registration',
                });
              }}
              label="CONTINUE"
              variant="primary-large"
              fullWidth
            />
          )}
        </ButtonHolder>
        {resendSeconds !== 0 && numberOfResends !== 2 && (
          <TimerHolder>
            <SAText
              text={`Resend in ${formatMinutes(resendSeconds)}s`}
              type="small"
              alignment="center"
            />
          </TimerHolder>
        )}
        {resendSeconds === 0 && numberOfResends === 0 && (
          <TimerHolder>
            <SAText
              test-attr="no-access-button-timer"
              text="Resend verification code"
              type="small"
              alignment="center"
              colorVariant="link"
              onClick={() => {
                recallVerificationGeneration();
                setResendSeconds(countdownLength ?? 300);
                setNumberOfResends(1);
                simpleGTMDataLayer({
                  event: `Registration-VerificationCodeResend-click`,
                  event_action: 'Resend Code Click',
                  event_category: 'Registration',
                  event_label: 'Registration Verification Code Resend Click',
                  event_category_snowplow: 'Snowplow Registration',
                });
              }}
            />
          </TimerHolder>
        )}
        {resendSeconds === 0 && numberOfResends === 1 && (
          <TimerHolder>
            <SAText
              test-attr="second-no-access-button-timer"
              text="Resend verification code"
              type="small"
              alignment="center"
              colorVariant="link"
              onClick={() => {
                recallVerificationGeneration();
                setNumberOfResends(2);
                simpleGTMDataLayer({
                  event: `Registration-VerificationCodeResendSecond-click`,
                  event_action: 'Resend Code Click',
                  event_category: 'Registration',
                  event_label:
                    'Registration Verification Code Resend Second Click',
                  event_category_snowplow: 'Snowplow Registration',
                });
              }}
            />
          </TimerHolder>
        )}
        {numberOfResends === 2 && (
          <TimerHolder>
            <SAText
              text="New code sent. We cannot resend more verification codes."
              type="small"
              alignment="center"
            />
          </TimerHolder>
        )}
        <BottomTextHolder>
          <SAText
            test-attr="no-access-button"
            text="Don't have access to this email? Get help now"
            type="small"
            alignment="center"
            colorVariant="link"
            onClick={() => {
              setError(true);
              simpleGTMDataLayer({
                event: `Registration-GetHelpLink-click`,
                event_action: 'Get Help Now Link Click',
                event_category: 'Registration',
                event_label: 'Registration Get Help Link Click',
                event_category_snowplow: 'Snowplow Registration',
              });
            }}
          />
        </BottomTextHolder>
      </Card>
    </>
  );
};

export default VerificationCodeModal;
