import React from 'react';
import {
  SAButton,
  SAText,
  SARadio,
  SARadioGroup,
} from '@saux/design-system-react';
import {
  ButtonHolder,
  HeadingTextHolder,
  Card,
  RadioWrapper,
  RadioWrap,
  ErrorHolder,
} from './AccountTypeStyles';

interface AccountTypeProps {
  onContinue: Function;
  error: boolean;
  setError: Function;
}

export const AccountType = ({
  onContinue,
  error,
  setError,
}: AccountTypeProps) => {
  return (
    <Card>
      <HeadingTextHolder>
        <SAText
          text="Are you registering a personal or business account?"
          type="heading-3"
          alignment="center"
        />
      </HeadingTextHolder>
      <RadioWrapper>
        <SARadioGroup id="accountType" variant="standard" direction="vertical">
          <SARadio
            value="Personal"
            type="radio"
            label="Personal"
            variant="standard"
          />
          <RadioWrap />
          <SARadio
            value="Business"
            type="radio"
            label="Business / Farm"
            variant="standard"
          />
        </SARadioGroup>
      </RadioWrapper>
      <ButtonHolder>
        <SAButton
          test-attr="account-types"
          onClick={() => {
            if (error) setError(false);
            onContinue();
          }}
          label="CONTINUE"
          variant="primary-large"
          fullWidth
        />
      </ButtonHolder>
      {error && (
        <ErrorHolder>
          <SAText colorVariant="alert" text="Please make a selection" />
        </ErrorHolder>
      )}
    </Card>
  );
};

export default AccountType;
