export default (contact: string | null): string | null => {
  let masked: string = '-';
  if (contact) {
    if (contact.includes('@')) {
      const first = contact.substring(0, 1);
      const last = contact.substring(contact.indexOf('@') - 2, contact.length);
      masked = `${first}xxxx${last}`;
    } else {
      const last = contact.substring(contact.length - 4);
      masked = `xxx-xxx-${last}`;
    }
    return masked;
  }
  return contact;
};
