import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from './util/global.theme';

const StyledInnerContentWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-top: 50px;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        padding-top: ${theme?.size.spacer.large};
      }
    `;
  }};
`;

export default StyledInnerContentWrapper;
