/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PopoverModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          width: 500px;
          height: 350px;
      }
      padding: 25px;
      width: 100vw;
      height: auto;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
    `;
  }}
`;

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        padding: 20px;
        margin: auto auto 20px auto;
      }
      margin: 50px auto 50px auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      width: 725px;
      padding: ${theme?.size?.spacer?.large} 50px ${theme?.size?.spacer?.large} 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
   `;
  }}
`;

export const TextSpacerRight = styled.div`
  width: 110px;
`;

export const WideTextSpacerRight = styled.div`
  width: 155px;
`;

export const CheckWrapper = styled.div`
  padding: 3px 0px 0px 8px;
`;

export const GreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TextSpacerRightLarger = styled.div`
  width: 130px;
`;

export const InputsHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 50%;
    margin: auto;
    padding-bottom: ${theme.size.spacer.small};
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
      width: 100%;
    }
`}
`;

export const ButtonHolder = styled.div`
  width: 100%;
  padding: 0px 100px 0px 100px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;

export const TextHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: column;
      margin: ${theme.size.spacer.xs} ${theme.size.spacer.xs} ${theme.size.spacer.large} ${theme.size.spacer.xs};
    `;
  }}
`;

export const InputWrap = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin: ${theme.size.spacer.xs} 0px 25px 0px;
    `;
  }}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 7px 0px 7px 0px;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }
`;

export const MoreSpacedRow = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 7px 0px ${theme.size.spacer.medium} 0px;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        flex-direction: column;
        justify-content: center;
        padding: 0px;
      }
    `;
  }}
`;

export const MoreSpacedRowAlignStart = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 7px 0px ${theme.size.spacer.medium} 0px;
    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: column;
      justify-content: center;
      padding: 0px;
    }
  `;
  }}
`;

export const NotWideInput = styled.div`
  width: 20%;
`;

export const WideInput = styled.div`
  width: 37%;
`;

export const WiderInput = styled.div`
  width: 50%;
`;

export const TextSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-bottom: ${theme.size.spacer.large};
  `;
  }}
`;

export const SmallerTextSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-bottom: ${theme.size.spacer.small};
  `;
  }}
`;

export const StepText = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  padding-bottom: 20px;
`;

export const SmallInputWrap = styled.div`
  margin-bottom: 8px;
`;

export const InnerTextHolder = styled.div`
  margin-top: 20px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin-top: 0px;
  }
`;

export const ContactOptionsHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin: 70px 40px 40px 40px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        margin: 40px ${theme.size.spacer.small} ${theme.size.spacer.small} ${theme.size.spacer.small};
      }
    `;
  }}
`;

export const RedirectButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background: #0173c6;
      height: 50px;
      box-shadow: 0px ${theme.size.radius.small} ${theme.size.radius.small} rgba(0, 0, 0, 0.25);
      border-radius: ${theme.size.radius.small};
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      color: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      justify-content: center;
      margin-top: ${theme.size.spacer.medium};
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;
