export interface IPersonalInformation {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth?: string;
  phone?: string;
  phoneType?: string;
};
export interface ILoginInformation {
  username: string;
  password: string;
};
export interface IScreenValidation {
  error: Array<string> | undefined,
  warning: Array<string> | undefined
}
export interface ICustomerRegistration {
  personalInformation: IPersonalInformation;
  loginInformation: ILoginInformation;
  businessType: string;
  account: string;
  guid: string;
};
export interface IRegisterUserResponse {
  firstName: string,
  lastName: string,
  phone: string,
  phoneType: string,
  email: string,
  guid: string,
  businessType: string,
  registeredToken: string,
  errorMessage: string,
  successMessage: string,
  lobIndicator: string,
  account: string
  isRegistered: boolean,
  isDisabled: boolean,
  expired: boolean,
};
export interface IQuestionsChallenge {
  questionText: string,
  answer: string
}
export interface IRequestRegistration {
  guid: string,
  accountnumber: string,
  lobIndicator: string,
  environment?: string,

  firstname: string,
  lastname: string,
  dateofbirth: string,
  displayname: string,
  email: string,

  familyName: string,
  givenName: string,
  secondaryPhoneNumber: string
  mobilenumber: string,
  phone: string
  
  username: string,
  passwd: string,
  passwdPolicyValidation?: string,
  sailpointToken?: string,
  
  kbas: Array<IQuestionsChallenge>
};
export enum RegisterReducerEnum {
  PERSONAL,
  LOGIN,
  ALL,
}
export interface IRegistrationErrorResponse {
  status: boolean,
  failureReason: string,
  ampStatus: boolean
}
export const CustomerRegistrationInitialState: ICustomerRegistration = {
  businessType: "",
  account: "",
  guid: "",
  personalInformation: {
    firstName: "",
    lastName: "",
    email: "",
  },
  loginInformation: {
    username: "",
    password: ""
  }
};
