import React from 'react';
import {
  SAButton,
  SATable,
  SATableColumn,
  SAText,
} from '@saux/design-system-react';
import {
  ButtonHolder,
  HeadingTextHolder,
  LowerTextHolder,
  Card,
  TableWrapper,
} from './MultipleAccountsAssociatedStyles';
import getConfig, { Env } from '../../aws_exports';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface MultipleAccountsAssociatedProps {
  tableColumns: SATableColumn[];
  tableData: any[][];
  onContinue: Function;
  setClickedRowIndex: Function;
  error: boolean;
  setError: Function;
}

export const MultipleAccountsAssociated = ({
  tableColumns,
  tableData,
  onContinue,
  setClickedRowIndex,
  error,
  setError,
}: MultipleAccountsAssociatedProps) => {
  return (
    <Card>
      <HeadingTextHolder>
        <SAText
          text={`We have found ${tableData.length} accounts associated with this email. Select an unregistered account to proceed.`}
          type="heading-3"
          alignment="center"
        />
      </HeadingTextHolder>
      <TableWrapper>
        <SATable
          onRowClick={(evt, index: number) => {
            if (error) setError(false);
            setClickedRowIndex(index);
          }}
          data={tableData}
          columns={tableColumns}
          variant="table-to-listview"
        />
      </TableWrapper>
      <ButtonHolder>
        {error ? (
          <>
            <SAButton
              test-attr="continue-multiple-accounts-associated-error"
              onClick={() => {
                setError(false);
              }}
              label="CONTINUE"
              variant="primary-large"
              fullWidth
            />
            <SAText
              colorVariant="alert"
              type="small"
              text="Please make a selection"
            />
          </>
        ) : (
          <SAButton
            test-attr="continue-multiple-accounts-associated"
            onClick={() => {
              onContinue();
              simpleGTMDataLayer({
                event: `Registration-MultipleAccountsContinueButton-click`,
                event_action: 'Button Click',
                event_category: 'Registration',
                event_label:
                  'Registration Multiple Accounts Continue Button Click',
                event_category_snowplow: 'Snowplow Registration',
              });
            }}
            label="CONTINUE"
            variant="primary-large"
            fullWidth
          />
        )}
      </ButtonHolder>
      <LowerTextHolder>
        <SAText text="Account already registered?&nbsp;" />
        <SAText
          text="Return to Login page"
          test-attr="return-to-login-multiple-accounts-associated"
          colorVariant="link"
          onClick={() => window.open(awsExports.endpoint.customer360, '_self')}
        />
      </LowerTextHolder>
    </Card>
  );
};

export default MultipleAccountsAssociated;
