/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        height: auto;
        padding: 20px;
        margin: auto auto 20px auto;
      }
      margin: 50px auto 50px auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      height: 500px;
      width: 725px;
      padding: ${theme?.size?.spacer?.large} 50px ${theme?.size?.spacer?.large} 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
   `;
  }}
`;

export const VerificationCodeInputHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 100%;
    margin: auto;
    padding-bottom: ${theme.size.spacer.small};
`}
`;

export const ButtonHolder = styled.div`
  width: 100%;
  padding: 0px 100px 0px 100px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;

export const InputHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WideInput = styled.div`
  padding: 5px 0px 5px 0px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 37%;
    padding: 0px;
  }
`;

export const DOBWideInput = styled.div`
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 37%;
    padding-bottom: 10px;
  }
`;

export const NotWideInput = styled.div`
  padding: 5px 0px 5px 0px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 20%;
    padding: 0px;
  }
`;

export const RowInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 0px 7px 0px;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }
`;
