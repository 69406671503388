import React, { useEffect, useReducer, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import * as TXT from './RegistrationUtils';
import { LoadingSpinner } from './LoadingSpinner';
import { RegistrationFormContainer } from '../../components/registrationFormContainer/registrationFormContainer';
import { GenericExceptionView } from './GenericExceptionView';
import { retrieveRegistration } from '../../service/CustomerRegistrationService';
import { completeRegistration } from '../../service/CompleteRegistrationService'
import {
  IRegisterUserResponse,
  CustomerRegistrationInitialState,
  RegisterReducerEnum,
  IRequestRegistration,
  IScreenValidation,
  IRegistrationErrorResponse,
} from '../../data/RegistrationData';
import { reducerCustomerInformation } from '../../data/RegistrationReducers';
import getConfig, { Env } from '../../aws_exports';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);
interface RegistrationViewProps extends RouteComponentProps {
  guid?: string;
  env?: string;
}

export const RegistrationView = (props: RegistrationViewProps) => {
  const [loading, isLoading] = useState(true);
  const [lob, setLob] = useState('');
  const [validationMessages, setValidationMessages] = useState<IScreenValidation>({error: undefined, warning: undefined});
  const [resultScreen, showResultScreen] = useState<TXT.Screen>(
    TXT.Screen.REGISTER,
  );
  const [registerUser, dispatchCustomerUserInfo] = useReducer(
    reducerCustomerInformation,
    CustomerRegistrationInitialState
  );
  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }
  const completeAndRedirect = () => {
    showResultScreen(TXT.Screen.COMPLETED);
    timeout(3000).then(() => {
      window.location.assign(awsExports.endpoint.customer360);
    });
  };
  const buildValidationArray = (errorResponse: IRegistrationErrorResponse) => {
    const responseArray: Array<string> = [];
    if (errorResponse && errorResponse.failureReason?.toLowerCase().includes("userpresent")) {
      responseArray.push(TXT.ERROR_USER_EXISTS);
    }
    if (errorResponse && errorResponse.failureReason?.toLowerCase().includes("dob")) {
      responseArray.push(TXT.ERROR_DOB);
    }
    if (errorResponse && errorResponse.failureReason?.toLowerCase().includes("phone")) {
      responseArray.push(TXT.ERROR_PHONE);
    }
    if (responseArray.length > 0) {
      setValidationMessages({
        warning: undefined,
        error: responseArray
      });
    }
  }
  const performRegistrationRequest = (regRequest: IRequestRegistration, currentEnv: string | undefined) => {
    if (regRequest && currentEnv) {
      isLoading(true);
      completeRegistration(regRequest, currentEnv)
      .then((responseRegistration) => {
        if (responseRegistration.status && responseRegistration.ampStatus) {
          completeAndRedirect();
        } else {
          showResultScreen(TXT.Screen.REGISTRATION_FAIL);
        }
      })
      .catch((failRegistration) => {
        if (failRegistration.response?.status < 500) {
          buildValidationArray(failRegistration.response.data);
          showResultScreen(TXT.Screen.REGISTER);
        } else if (failRegistration.response?.data.failureReason.includes('503')) {
          showResultScreen(TXT.Screen.REGISTRATION_FAIL);
        } else {
          showResultScreen(TXT.Screen.SERVER_DOWN);
        }
      })
      .finally(() => {
        isLoading(false);
      });
    }
  };
  const { guid, env }  = props;
  useEffect(() => {
    if (guid && env) {
      retrieveRegistration(guid, env)
        .then((responseUser: IRegisterUserResponse) => {
          setLob(responseUser.lobIndicator);
          const customerReg = TXT.mapCustomerRegistrationResponse(responseUser);
          dispatchCustomerUserInfo(TXT.buildServiceUpdateAction(customerReg));
        }).catch((error) => {
          if (error.response?.status === 302) {
            window.location.assign(error.response?.data);
          }
          if (error.response?.status >= 500) {
            showResultScreen(TXT.Screen.SERVER_DOWN);
          } else if (error.response?.status === 403 && error.response?.data?.includes("expired")) {
            showResultScreen(TXT.Screen.EXPIRED);
          } else if (error.response?.status === 403 && error.response?.data?.includes("registered")) {
            completeAndRedirect();
          } else {
            showResultScreen(TXT.Screen.NO_USER_INFO);
          }
        }).finally(() => {
          isLoading(false);
        });
    }
  }, [guid]);
  const submitRegistration = (submitBody: IRequestRegistration)=>{
    let regRequest = submitBody;
    if (!env?.toLowerCase().includes('l4')) {
      regRequest = {
        ...submitBody,
        environment: env?.toUpperCase()
      }
    }
    regRequest.lobIndicator = lob;
    performRegistrationRequest(regRequest, env);
  };
  
  return (
    <>
      {loading && <LoadingSpinner />}
      { (!loading && resultScreen === TXT.Screen.REGISTER ) &&
        <RegistrationFormContainer
          registerUser={registerUser}
          validationMessages={validationMessages}
          handleSubmitRegistration={submitRegistration}
        />
      }
      {
        (!loading && resultScreen === TXT.Screen.SERVER_DOWN) &&
        <GenericExceptionView 
          headerException={TXT.TEXT_ERROR_SRVDOWN}
          messageException={TXT.TEXT_ERROR_SRVDOWN_2}
        />
      }
      {
        (!loading && resultScreen === TXT.Screen.NO_USER_INFO) &&
        <GenericExceptionView 
          headerException={TXT.TEXT_ERROR_NOUSERINFO}
          messageException={TXT.TEXT_ERROR_PHONE}
        />
      }
      {
        (!loading && resultScreen === TXT.Screen.EXPIRED) &&
        <GenericExceptionView 
          headerException={TXT.TEXT_ERROR_EXPIRED}
          messageException={TXT.TEXT_ERROR_PHONE}
        />
      }
      {
        (!loading && resultScreen === TXT.Screen.COMPLETED) &&
        <GenericExceptionView 
          headerException={TXT.TEXT_COMPLETED}
          messageException={TXT.TEXT_COMPLETED_2}
        />
      }
      {
        (!loading && resultScreen === TXT.Screen.REGISTRATION_FAIL) &&
        <GenericExceptionView 
          headerException={TXT.TEXT_ERROR_REGISTRATION}
          messageException={TXT.TEXT_ERROR_PHONE}
        />
      }
    </>
  );
};
export default RegistrationView;
