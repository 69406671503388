import React, { useEffect, useState } from 'react';
import { SAButton, SAInput, SAText } from '@saux/design-system-react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  ButtonHolder,
  CaptchaHolder,
  Card,
  EmailInputHolder,
} from './FirstEmailCheckModalStyles';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import getConfig, { Env } from '../../aws_exports';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface FirstEmailCheckModalProps {
  checkInputtedEmail: Function;
  setError: Function;
  loading: boolean;
  error: boolean;
  alreadyActiveEID: boolean;
  setAlreadyActiveEID: Function;
  accountType: string;
}

export const handleChange = (
  value: string | null,
  setCaptchaHasValue: Function,
) => {
  if (value && value.length > 1) setCaptchaHasValue(true);
};

export const FirstEmailCheckModal = ({
  checkInputtedEmail,
  loading,
  setError,
  error,
  alreadyActiveEID,
  setAlreadyActiveEID,
  accountType,
}: FirstEmailCheckModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [captchaHasValue, setCaptchaHasValue] = useState<boolean>(false);
  const [captchaScriptReady, setCaptchaScriptReady] = useState<boolean>(false);
  const key: string = awsExports.captcha_code;

  useEffect(() => {
    if ((window as any)?.grecaptcha?.enterprise?.ready) {
      setCaptchaScriptReady(true);
    }
  }, [window]);

  return (
    <Card>
      <SAText
        text={
          accountType === 'Personal'
            ? 'To start, please provide your email address.'
            : "Please provide the primary contact's email address."
        }
        type="heading-3"
      />
      <EmailInputHolder>
        {!alreadyActiveEID && !error && (
          <SAInput
            id="first-email-input"
            test-attr="first-email-input"
            fullWidth
            defaultValue={email}
            label="Email"
            type="text"
            onChange={(evt) => {
              setEmail(evt.target.value);
            }}
          />
        )}
        {error && !alreadyActiveEID && (
          <SAInput
            id="first-email-input-error"
            test-attr="first-email-input-error"
            hint="Please type your email in the correct format."
            type="text"
            label="Email"
            value={email}
            onClick={() => setError(false)}
            iconColor="alert"
            fullWidth
            error
            readOnly
          />
        )}
        {!error && alreadyActiveEID && (
          <>
            <SAInput
              id="first-email-input-already-active-input"
              test-attr="first-email-input-already-active-input"
              type="text"
              value={email}
              label="Email"
              onClick={() => setAlreadyActiveEID(false)}
              iconColor="alert"
              fullWidth
              error
              readOnly
            />
            <>
              <SAText
                type="small"
                text="This email address has already been registered. Sign in with the
                email or use a different one.&nbsp;"
              />
              <SAText
                test-attr="redirect-after-first-email-input-already-active"
                type="small"
                text="Click here to go back to the login
              page."
                colorVariant="link"
                onClick={() => {
                  window.open(awsExports.endpoint.customer360, '_self');
                  simpleGTMDataLayer({
                    event: `Registration-EmailAlreadyRegisteredBackToLogin-click`,
                    event_action: 'Return To Login Click',
                    event_category: 'Registration',
                    event_label:
                      'Registration Email Already Registered Back To Login Click',
                    event_category_snowplow: 'Snowplow Registration',
                  });
                }}
              />
            </>
          </>
        )}
      </EmailInputHolder>
      <CaptchaHolder>
        {captchaScriptReady && (
          <ReCAPTCHA
            theme="light"
            sitekey={key}
            onChange={(value) => handleChange(value, setCaptchaHasValue)}
          />
        )}
      </CaptchaHolder>
      <ButtonHolder>
        {loading ? (
          <SAButton label="CONTINUE" variant="primary-large" fullWidth>
            <SpinnerLight />
          </SAButton>
        ) : (
          <SAButton
            label="CONTINUE"
            variant="primary-large"
            fullWidth
            test-attr="first-inputted-email-continue"
            onClick={() => {
              simpleGTMDataLayer({
                event: `Registration-EnterEmailContinueButton-click`,
                event_action: 'Button Click',
                event_category: 'Registration',
                event_label: 'Registration Enter Email Continue Button Click',
                event_category_snowplow: 'Snowplow Registration',
              });
              if (captchaHasValue) checkInputtedEmail(email);
            }}
          />
        )}
      </ButtonHolder>
    </Card>
  );
};

export default FirstEmailCheckModal;
