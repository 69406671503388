/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        padding: ${theme.size.spacer.large};
        margin: auto auto 20px auto;
      }
      margin: auto auto 50px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      width: 625px;
      height: 400px;
      text-align: center;
      padding: 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
   `;
  }}
`;

export const EmailInputHolder = styled.div`
  width: 100%;
  margin: auto;
  padding: 0px 50px 0px 50px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;

export const CaptchaHolder = styled.div`
  display: inline-block;
  margin: auto;
  padding-bottom: 25px;
`;

export const ButtonHolder = styled.div`
  width: 100%;
  padding: 0px 50px 0px 50px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;
