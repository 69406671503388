import React from 'react';
import { SAButton, SAInput, SAText } from '@saux/design-system-react';
import {
  ButtonHolder,
  InputHolder,
  VerificationCodeInputHolder,
  RowInputs,
  WideInput,
  NotWideInput,
  DOBWideInput,
  Card,
} from './MoreInfoNeededModalCLStyles';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

interface MoreInfoNeededModalCLProps {
  verifyEnteredInfo: Function;
  loading: boolean;
  userInfo: UserInfo | undefined;
  setUserInfo: Function;
  primaryContactFirstNameError: boolean;
  setPrimaryContactFirstNameError: Function;
  primaryContactLastNameError: boolean;
  setPrimaryContactLastNameError: Function;
  primaryContactPhoneError: boolean;
  setPrimaryContactPhoneError: Function;
  primaryContactZipCodeError: boolean;
  setPrimaryContactZipCodeError: Function;
}

export interface UserInfo {
  primaryContactFirstName?: string;
  middleName?: string;
  primaryContactLastName?: string;
  primaryContactZipCode?: string;
  primaryContactPhone?: string;
}

export const MoreInfoNeededModalCL = ({
  verifyEnteredInfo,
  userInfo,
  setUserInfo,
  loading,
  primaryContactFirstNameError,
  setPrimaryContactFirstNameError,
  primaryContactLastNameError,
  setPrimaryContactLastNameError,
  primaryContactPhoneError,
  setPrimaryContactPhoneError,
  primaryContactZipCodeError,
  setPrimaryContactZipCodeError,
}: MoreInfoNeededModalCLProps) => {
  return (
    <Card>
      <SAText
        text="This email is not associated with any account in our records. Please provide more information for us to locate your account."
        type="heading-3"
        alignment="center"
      />
      <VerificationCodeInputHolder>
        <InputHolder>
          <RowInputs>
            <WideInput>
              {!primaryContactFirstNameError ? (
                <SAInput
                  id="more-info-primary-contact-first-name-input"
                  label="Primary Contact First Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactFirstName}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      primaryContactFirstName: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-primary-contact-first-name-input"
                  test-attr="more-info-primary-contact-first-name-input-error"
                  label="Primary Contact First Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactFirstName}
                  onClick={() => setPrimaryContactFirstNameError(false)}
                  hint="Please fill in your first name"
                  error
                  readOnly
                />
              )}
            </WideInput>
            <NotWideInput>
              <SAInput
                id="more-info-middle-name-input"
                label="Middle Name"
                hint="(Optional)"
                type="search"
                autoComplete="off"
                fullWidth
                onChange={(evt) => {
                  setUserInfo({
                    ...userInfo,
                    middleName: evt.target.value,
                  });
                }}
              />
            </NotWideInput>
            <WideInput>
              {!primaryContactLastNameError ? (
                <SAInput
                  id="more-info-primary-contact-last-name-input"
                  label="Primary Contact Last Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactLastName}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      primaryContactLastName: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-primary-contact-last-name-input"
                  test-attr="more-info-primary-contact-last-name-input-error"
                  label="Primary Contact Last Name"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactLastName}
                  onClick={() => setPrimaryContactLastNameError(false)}
                  hint="Please fill in your last name"
                  error
                  readOnly
                />
              )}
            </WideInput>
          </RowInputs>
          <RowInputs>
            <DOBWideInput>
              {!primaryContactPhoneError ? (
                <SAInput
                  id="more-info-primary-contact-phone-input"
                  label="Primary Contact Phone"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactPhone}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      primaryContactPhone: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-primary-contact-phone-input"
                  test-attr="more-info-primary-contact-phone-error"
                  label="Primary Contact Phone"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactPhone}
                  onClick={() => setPrimaryContactPhoneError(false)}
                  hint="Please fill in your primary contact phone"
                  error
                  readOnly
                />
              )}
            </DOBWideInput>
          </RowInputs>
          <RowInputs>
            <WideInput>
              {!primaryContactZipCodeError ? (
                <SAInput
                  id="more-info-zip-code-input"
                  label="Zip Code"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactZipCode}
                  onChange={(evt) => {
                    setUserInfo({
                      ...userInfo,
                      primaryContactZipCode: evt.target.value,
                    });
                  }}
                />
              ) : (
                <SAInput
                  id="more-info-zip-code-input"
                  test-attr="more-info-zip-code-input-error"
                  label="Zip Code"
                  type="search"
                  autoComplete="off"
                  fullWidth
                  defaultValue={userInfo?.primaryContactZipCode}
                  onClick={() => setPrimaryContactZipCodeError(false)}
                  hint="Please fill in your zip code"
                  error
                  readOnly
                />
              )}
            </WideInput>
          </RowInputs>
        </InputHolder>
      </VerificationCodeInputHolder>
      <ButtonHolder>
        {!loading ? (
          <SAButton
            test-attr="continue-more-info-needed-screen"
            onClick={() => {
              verifyEnteredInfo();
              simpleGTMDataLayer({
                event: `Registration-MoreInfoContinueButtonCL-click`,
                event_action: 'Button Click',
                event_category: 'Registration',
                event_label: 'Registration More Info Continue Button CL Click',
                event_category_snowplow: 'Snowplow Registration',
              });
            }}
            label="CONTINUE"
            variant="primary-large"
            fullWidth
          />
        ) : (
          <SAButton label="CONTINUE" variant="primary-large" fullWidth>
            <SpinnerLight />
          </SAButton>
        )}
      </ButtonHolder>
    </Card>
  );
};

export default MoreInfoNeededModalCL;
