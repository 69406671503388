import React from 'react';
import { SAText } from '@saux/design-system-react';
import { ReactComponent as Loading } from '../../../assets/images/Loading.svg';
import {
  PopoverModal,
  InnerTextHolder,
  LoadingHolder,
} from '../PostVerifiedModalsStyles';

interface LoadingModalProps {
  message: string | undefined;
}

export const LoadingModal = ({ message }: LoadingModalProps) => {
  return (
    <>
      <PopoverModal>
        <InnerTextHolder>
          <SAText
            text={message ?? 'Loading...'}
            type="heading-3"
            alignment="center"
          />
        </InnerTextHolder>
        <LoadingHolder>
          <Loading />
        </LoadingHolder>
      </PopoverModal>
    </>
  );
};

export default LoadingModal;
