import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { VerificationCodeModal } from './VerificationCodeModal';
import getConfig, { Env } from '../../aws_exports';
import maskContacts from '../../util/formatters/maskContent';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface VerificationCodeModalDataContainerProps {
  setVerificationView: Function;
  setNotVerified: Function;
  setVerifiedLoading: Function;
  setSignUpView: Function;
  selectedVerificationMethod: MutableRefObject<string>;
  verificationMethodValue: string;
}

export const generateVerificationCode = async (
  accountNumber: string,
  contact: string,
) => {
  const data = {
    accountNumber,
    ...(contact?.includes('@') ? { email: contact } : { phone: contact }),
  };

  await axios({
    method: 'post',
    url: awsExports.gateway_endpoints.verificationCode,
    data,
  });
};

export const verifyEmailCode = async (accountNumber: string, code: string) => {
  let match = false;
  const postData = {
    accountNumber,
    code,
  };

  await axios({
    method: 'post',
    url: awsExports.gateway_endpoints.verifyVerificationCode,
    data: postData,
  })
    .then((response) => {
      response.data = JSON.parse(atob(response.data));
      if (response.data || (awsExports.env === 'L3-7' && code === '123456')) {
        match = true;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return match;
};

const VerificationCodeModalDataContainer = ({
  setVerificationView,
  setNotVerified,
  setVerifiedLoading,
  setSignUpView,
  selectedVerificationMethod,
  verificationMethodValue,
}: VerificationCodeModalDataContainerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inputtedCode, setInputtedCode] = useState<string | undefined>(
    undefined,
  );
  const [numberOfResends, setNumberOfResends] = useState<number>(0);
  const [inputError, setInputError] = useState<boolean>(false);
  const savedAccountNumber = JSON.parse(
    sessionStorage.getItem('returnedAccountAttributesCollection') ??
      '[{"account_number":null}]',
  )[0].account_number;
  const foundUserName = maskContacts(verificationMethodValue);

  const recallVerificationGeneration = async () => {
    setInputtedCode('');
    await generateVerificationCode(
      savedAccountNumber,
      selectedVerificationMethod.current,
    );
  };

  const verifyInput = useCallback(async () => {
    if (inputtedCode && inputtedCode.length === 6) {
      if (await verifyEmailCode(savedAccountNumber, inputtedCode)) {
        setVerifiedLoading(true);
        setVerificationView(false);
        setTimeout(() => {
          setVerifiedLoading(false);
          setSignUpView(true);
        }, 4000);
      } else if (
        numberOfResends === 2 &&
        !(await verifyEmailCode(savedAccountNumber, inputtedCode))
      ) {
        setNotVerified(true);
        setVerificationView(false);
      } else {
        setInputError(true);
      }
      setLoading(false);
    }
  }, [inputtedCode]);

  useEffect(() => {
    if (inputtedCode && inputtedCode.length === 6) {
      verifyInput();
    }
  }, [inputtedCode]);

  return (
    <VerificationCodeModal
      setInputtedCode={setInputtedCode}
      foundUserName={foundUserName}
      numberOfResends={numberOfResends}
      setNumberOfResends={setNumberOfResends}
      inputError={inputError}
      setInputError={setInputError}
      recallVerificationGeneration={recallVerificationGeneration}
      setLoading={setLoading}
      loading={loading}
    />
  );
};

export default VerificationCodeModalDataContainer;
