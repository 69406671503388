import React from 'react';
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const TextSpacer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-top: ${theme.size.spacer.large};
      margin-bottom: ${theme.size.spacer.large};
  `;
  }}
`;
export const MainElementContainer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
        flex-flow: column wrap;
        justify-content: center;
      }
    `;
  }}
`;
export const ElementContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  justify-content: start;
`;
export const LabelWrapper = styled.div`
  width: 22%
`;
export const InputWrapper = styled.div`
  width: 48%
`;
export const ErrorWrapper = styled.div`
  width: 30%;
  text-align: left;
  padding: 0 0 0 15px;
  white-space: pre-line;
`;