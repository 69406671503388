/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatTitleCase } from '../../util';
import { SignUpModal, SignedUpUserInfo } from './SignUpModal';
import getConfig, { Env } from '../../aws_exports';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface SignUpModalDataContainerProps {
  setNotVerified: Function;
  setSignUpView: Function;
  accountType: string;
  inputtedEmailNotFound: boolean;
  showEmailAsDefaultUsername: boolean;
}

interface CheckIfUserNameIsTakenProps {
  userName: string | undefined;
  setTakenUserName: Function;
  setLoading: Function;
  setShowSetPasswordModal: Function;
  setShowSetQuestionsModal: Function;
  setError: Function;
  setInvalidUsername: Function;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordIsLongEnough: boolean;
  passwordHasSpecialChar: boolean;
}

interface CheckTraitsAndRegisterProps {
  accountType: string;
  setLoading: Function;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  foundUserName: string | undefined;
  setTakenUserName: Function;
  setShowConfirmUserInfoModal: Function;
  setShowFinalRedirectModal: Function;
  setSignUpView: Function;
  setNotVerified: Function;
  savedUserAttributes: object | string | any;
  inputtedEmailNotFound: boolean;
}

export const trueCountReached: Function = (arr: boolean[]): boolean => {
  const count = arr.filter(Boolean).length;

  if (count > 3) return true;
  return false;
};

export const checkIfUserNameIsTaken: Function = async ({
  userName,
  setTakenUserName,
  setLoading,
  setShowSetPasswordModal,
  setShowSetQuestionsModal,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordIsLongEnough,
  passwordHasSpecialChar,
  setError,
  setInvalidUsername,
}: CheckIfUserNameIsTakenProps) => {
  if (userName) {
    if (/[|&;$%?!:'"<>()+,={}^*\s]/g.test(userName)) {
      setInvalidUsername(true);
    } else {
      setLoading(true);
      const postData = {
        username: userName,
      };

      await axios({
        method: 'post',
        url: awsExports.gateway_endpoints.getUserByEmail,
        data: postData,
      })
        .then((response) => {
          if (response.data) {
            response.data = JSON.parse(atob(response.data));
            if (response.data.alreadyActiveInEID) {
              setTakenUserName(true);
            } else if (
              (!passwordIsLongEnough ||
                !trueCountReached([
                  passwordHasCapital,
                  passwordHasNumber,
                  passwordHasLowercase,
                  passwordHasSpecialChar,
                ])) &&
              response.data.alreadyActiveInEID === false
            ) {
              setError(true);
            } else if (
              passwordIsLongEnough &&
              trueCountReached([
                passwordHasCapital,
                passwordHasNumber,
                passwordHasLowercase,
                passwordHasSpecialChar,
              ]) &&
              response.data.alreadyActiveInEID === false
            ) {
              setShowSetPasswordModal(false);
              setShowSetQuestionsModal(true);
            }
          }
        })
        .catch((err) => {
          console.log('Error checking for duplicate username: ', err);
          setTakenUserName(true);
        });
      setLoading(false);
    }
  }
};

export const checkTraitsAndRegister = async ({
  accountType,
  setLoading,
  signedUpUserInfo,
  foundUserName,
  setTakenUserName,
  savedUserAttributes,
  setShowConfirmUserInfoModal,
  setShowFinalRedirectModal,
  setSignUpView,
  setNotVerified,
  inputtedEmailNotFound,
}: CheckTraitsAndRegisterProps) => {
  setLoading(true);
  const userNameCheckPostData = {
    email: signedUpUserInfo?.userName ?? foundUserName,
  };

  axios({
    method: 'post',
    url: awsExports.gateway_endpoints.getUserByEmail,
    data: userNameCheckPostData,
  })
    .then(async (response) => {
      if (response.data) {
        response.data = JSON.parse(atob(response.data));
        if (response.data.alreadyActiveInEID) {
          setLoading(false);
          setTakenUserName(true);
        } else {
          const postData = {
            username: `${signedUpUserInfo?.userName ?? foundUserName}`,
            accountnumber: `${savedUserAttributes.account_number}`,
            dateofbirth: signedUpUserInfo?.dateOfBirth,
            firstname: signedUpUserInfo?.firstName?.toUpperCase(),
            lastname: signedUpUserInfo?.lastName?.toUpperCase(),
            email: inputtedEmailNotFound
              ? savedUserAttributes.email_address
              : `${foundUserName}`,
            familyName: signedUpUserInfo?.lastName?.toUpperCase(),
            givenName: signedUpUserInfo?.firstName?.toUpperCase(),
            passwd: signedUpUserInfo?.password,
            phone: signedUpUserInfo?.phone,
            kbas: [
              {
                questionText: signedUpUserInfo?.firstSecurityQuestion,
                answer: signedUpUserInfo?.firstSecurityQuestionAnswer,
              },
              {
                questionText: signedUpUserInfo?.secondSecurityQuestion,
                answer: signedUpUserInfo?.secondSecurityQuestionAnswer,
              },
              {
                questionText: signedUpUserInfo?.thirdSecurityQuestion,
                answer: signedUpUserInfo?.thirdSecurityQuestionAnswer,
              },
            ],
            environment: awsExports.env,
            sourceApp: 'Customer360',
            lobIndicator:
              accountType === 'Personal' ? 'personal' : 'commercial',
          };

          axios({
            method: 'post',
            url: awsExports.gateway_endpoints.registerUser,
            data: postData,
            timeout: 60000,
          })
            .then((res) => {
              const parsedResponse = JSON.parse(
                res.data ? atob(res.data) : '""',
              );
              setLoading(false);
              if (parsedResponse !== '') {
                if (typeof parsedResponse.body.failureReason === 'string') {
                  setSignUpView(false);
                  setNotVerified(true);
                } else {
                  setShowConfirmUserInfoModal(false);
                  setShowFinalRedirectModal(true);
                }
              } else {
                setSignUpView(false);
                setNotVerified(true);
              }
            })
            .catch((callErr) => {
              console.log('Registration call error: ', callErr);
              setLoading(false);
              setSignUpView(false);
              setNotVerified(true);
            });
        }
      }
    })
    .catch((err) => {
      setLoading(false);
      console.log('Error checking for duplicate username: ', err);
      setTakenUserName(true);
    });
};

const SignUpModalDataContainer = ({
  setNotVerified,
  setSignUpView,
  accountType,
  inputtedEmailNotFound,
  showEmailAsDefaultUsername,
}: SignUpModalDataContainerProps) => {
  const [signedUpUserInfo, setSignedUpUserInfo] = useState<
    SignedUpUserInfo | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [showSetPasswordModal, setShowSetPasswordModal] =
    useState<boolean>(true);
  const [showSetQuestionsModal, setShowSetQuestionsModal] =
    useState<boolean>(false);
  const [showFinalRedirectModal, setShowFinalRedirectModal] =
    useState<boolean>(false);
  const [showConfirmUserInfoModal, setShowConfirmUserInfoModal] =
    useState<boolean>(false);
  const [takenUserName, setTakenUserName] = useState<boolean>(false);
  const [invalidUsername, setInvalidUsername] = useState<boolean>(false);
  const [savedUserAttributes, setSavedUserAttributes] = useState<string | any>(
    '',
  );

  // Password Validation States
  const [passwordIsLongEnough, setPasswordIsLongEnough] =
    useState<boolean>(false);
  const [passwordHasCapital, setPasswordHasCapital] = useState<boolean>(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState<boolean>(false);
  const [passwordHasLowercase, setPasswordHasLowercase] =
    useState<boolean>(false);
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] =
    useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] =
    useState<boolean>(false);

  const displayFirstName = formatTitleCase(
    sessionStorage.getItem('returnedGivenName'),
  );
  const foundUserName = sessionStorage.getItem('inputtedEmail') ?? undefined;

  useEffect(() => {
    if (sessionStorage.getItem('returnedAccountAttributesCollection')) {
      setSavedUserAttributes(
        JSON.parse(
          sessionStorage.getItem('returnedAccountAttributesCollection') ??
            '[""]',
        )[0],
      );
    }
  }, []);

  useEffect(() => {
    if (signedUpUserInfo?.password) {
      if (signedUpUserInfo?.password.length > 11) {
        setPasswordIsLongEnough(true);
      } else setPasswordIsLongEnough(false);
      if (/[A-Z]/g.test(signedUpUserInfo?.password)) {
        setPasswordHasCapital(true);
      } else setPasswordHasCapital(false);
      if (/[a-z]/g.test(signedUpUserInfo?.password)) {
        setPasswordHasLowercase(true);
      } else setPasswordHasLowercase(false);
      if (/[0-9]/g.test(signedUpUserInfo?.password)) {
        setPasswordHasNumber(true);
      } else setPasswordHasNumber(false);
      if (
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(
          signedUpUserInfo?.password,
        )
      ) {
        setPasswordHasSpecialChar(true);
      } else setPasswordHasSpecialChar(false);
    } else {
      setPasswordIsLongEnough(false);
      setPasswordHasCapital(false);
      setPasswordHasNumber(false);
      setPasswordHasLowercase(false);
      setPasswordHasSpecialChar(false);
    }
  }, [signedUpUserInfo?.password]);

  useEffect(() => {
    if (
      savedUserAttributes &&
      savedUserAttributes !== '' &&
      !signedUpUserInfo?.firstName &&
      !signedUpUserInfo?.lastName &&
      !signedUpUserInfo?.accountNumber &&
      !signedUpUserInfo?.dateOfBirth
    ) {
      const determinePrimaryPhone = () => {
        if (savedUserAttributes?.primary_phone === 'MOBILE') {
          return savedUserAttributes?.cell_phone ?? '';
        }
        if (savedUserAttributes?.primary_phone === 'WORK') {
          return savedUserAttributes?.work_phone ?? '';
        }
        return savedUserAttributes?.home_phone ?? '';
      };

      setSignedUpUserInfo({
        ...signedUpUserInfo,
        firstName: savedUserAttributes.first_name,
        lastName: savedUserAttributes.last_name,
        accountNumber: savedUserAttributes.account_number,
        dateOfBirth: savedUserAttributes.dob,
        phone: determinePrimaryPhone(),
      });
    }
  }, [[], savedUserAttributes]);

  const registerUser = async () => {
    if (
      !trueCountReached([
        passwordHasCapital,
        passwordHasNumber,
        passwordHasLowercase,
        passwordHasSpecialChar,
      ]) ||
      !passwordIsLongEnough
    ) {
      setPasswordError(true);
    }
    if (
      passwordIsLongEnough &&
      trueCountReached([
        passwordHasCapital,
        passwordHasNumber,
        passwordHasLowercase,
        passwordHasSpecialChar,
      ])
    ) {
      if (foundUserName) {
        if (/[|&;$%?!:'"<>()+,={}^*\s]/g.test(foundUserName)) {
          setInvalidUsername(true);
        } else
          return checkTraitsAndRegister({
            accountType,
            setLoading,
            signedUpUserInfo,
            foundUserName,
            setTakenUserName,
            savedUserAttributes,
            setShowConfirmUserInfoModal,
            setShowFinalRedirectModal,
            setSignUpView,
            setNotVerified,
            inputtedEmailNotFound,
          });
      } else if (signedUpUserInfo?.userName) {
        if (/[|&;$%?!:'"<>()+,={}^*\s]/g.test(signedUpUserInfo?.userName)) {
          setInvalidUsername(true);
        } else {
          return checkTraitsAndRegister({
            accountType,
            setLoading,
            signedUpUserInfo,
            foundUserName,
            setTakenUserName,
            savedUserAttributes,
            setShowConfirmUserInfoModal,
            setShowFinalRedirectModal,
            setSignUpView,
            setNotVerified,
            inputtedEmailNotFound,
          });
        }
      }
    }
    return null;
  };

  const checkUsernameAndPassword = () => {
    return checkIfUserNameIsTaken({
      userName: signedUpUserInfo?.userName ?? foundUserName,
      setTakenUserName,
      setLoading,
      setShowSetPasswordModal,
      setShowSetQuestionsModal,
      passwordHasCapital,
      passwordHasNumber,
      passwordHasLowercase,
      passwordIsLongEnough,
      passwordHasSpecialChar,
      setError,
      setInvalidUsername,
    });
  };

  return (
    <SignUpModal
      displayFirstName={displayFirstName ?? '-'}
      foundUserName={foundUserName}
      accountType={accountType}
      signedUpUserInfo={signedUpUserInfo}
      setSignedUpUserInfo={setSignedUpUserInfo}
      loading={loading}
      registerUser={registerUser}
      showFinalRedirectModal={showFinalRedirectModal}
      showConfirmUserInfoModal={showConfirmUserInfoModal}
      setShowConfirmUserInfoModal={setShowConfirmUserInfoModal}
      checkUsernameAndPassword={checkUsernameAndPassword}
      setTakenUserName={setTakenUserName}
      takenUserName={takenUserName}
      showSetPasswordModal={showSetPasswordModal}
      showSetQuestionsModal={showSetQuestionsModal}
      setShowSetQuestionsModal={setShowSetQuestionsModal}
      error={error}
      setError={setError}
      invalidUsername={invalidUsername}
      setInvalidUsername={setInvalidUsername}
      passwordHasCapital={passwordHasCapital}
      passwordHasNumber={passwordHasNumber}
      passwordHasLowercase={passwordHasLowercase}
      passwordHasSpecialChar={passwordHasSpecialChar}
      passwordError={passwordError}
      setPasswordError={setPasswordError}
      confirmedPassword={confirmedPassword}
      setConfirmedPassword={setConfirmedPassword}
      passwordsDoNotMatch={passwordsDoNotMatch}
      setPasswordsDoNotMatch={setPasswordsDoNotMatch}
      showEmailAsDefaultUsername={showEmailAsDefaultUsername}
    />
  );
};

export default SignUpModalDataContainer;
