/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { SATableColumn, SAText } from '@saux/design-system-react';
import { MultipleAccountsAssociated } from './MultipleAccountsAssociated';
import { formatTitleCase } from '../../util';
import { EmptyInput, LeftInput } from './MultipleAccountsAssociatedStyles';
import { mergeByAccountNumberAndSort } from '../accountAlreadyRegistered/DataContainer';

interface MultipleAccountsAssociatedDataContainerProps {
  setMultipleAccountsAssociatedView: Function;
  setVerificationView: Function;
}

interface FoundUserNameProps {
  associatedUserName: string;
  accountNumbers: any[];
}

export const radioClick = (content: string) => {
  if (sessionStorage.getItem('alreadyRegisteredAccount') !== content) {
    return (
      <div>
        <LeftInput type="radio" id={content} name="AccountSelection" />
        <span>{content}</span>
      </div>
    );
  }
  return (
    <EmptyInput>
      <div>{content}</div>
      <SAText type="standard" weight="bold" text="REGISTERED" />
    </EmptyInput>
  );
};

export const maskFoundUsername = (
  foundUserName: string | undefined,
): string => {
  let masked: string = '-';
  if (foundUserName) {
    if (foundUserName.includes('@')) {
      const first = foundUserName.substring(0, 1);
      const last = foundUserName.substring(
        foundUserName.indexOf('@') - 2,
        foundUserName.length,
      );
      masked = `${first}****${last}`;
    } else {
      const first = foundUserName.substring(0, 1);
      const last = foundUserName.substring(foundUserName.length - 3);
      masked = `${first}****${last}`;
    }
  }
  return masked;
};

export const organizeTableData = (
  mergedData: any,
  foundUserName: FoundUserNameProps | string | undefined,
): any[][] => {
  const rowInfo: any[][] = [];
  mergedData.forEach((obj: any) => {
    const accNumWording: string = `ending in xxx${obj.account_number.substr(
      obj.account_number.length - 3,
    )}`;

    const policyWording: string =
      formatTitleCase(obj.product_code) ?? 'Insured Policy';

    const maskedUsername: Function = (): string => {
      if (
        foundUserName &&
        typeof foundUserName !== 'string' &&
        foundUserName.associatedUserName
      ) {
        if (foundUserName.accountNumbers.includes(obj.account_number)) {
          sessionStorage.setItem('alreadyRegisteredAccount', accNumWording);
          return maskFoundUsername(foundUserName.associatedUserName);
        }
        return 'Not Registered';
      }
      return 'Not Registered';
    };

    rowInfo.push([
      `${accNumWording}`,
      `${policyWording}`,
      `${maskedUsername()}`,
    ]);
  });
  return rowInfo;
};

const tableColumns: SATableColumn[] = [
  {
    align: 'left',
    name: 'Account Number',
    process: radioClick,
  },
  {
    align: 'left',
    name: 'Policy Type',
  },
  {
    align: 'left',
    name: 'Username',
  },
];

const MultipleAccountsAssociatedDataContainer = ({
  setMultipleAccountsAssociatedView,
  setVerificationView,
}: MultipleAccountsAssociatedDataContainerProps) => {
  const [foundAccountData, setFoundAccountData] = useState<any[] | undefined>(
    undefined,
  );
  const [foundPolicyData, setFoundPolicyData] = useState<any[] | undefined>(
    undefined,
  );
  const [tableData, setTableData] = useState<any[][]>([]);
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(
    undefined,
  );
  const [error, setError] = useState<boolean>(false);

  const foundUserName: FoundUserNameProps | string | undefined =
    sessionStorage.getItem('registeredAccountsWithUserName')
      ? JSON.parse(
          sessionStorage.getItem('registeredAccountsWithUserName') ?? '[]',
        )
      : undefined;

  useEffect(() => {
    setFoundAccountData(
      JSON.parse(
        sessionStorage.getItem('returnedAccountAttributesCollection') ?? '[]',
      ),
    );
    setFoundPolicyData(
      JSON.parse(
        sessionStorage.getItem('returnedPolicyAttributesCollection') ?? '[]',
      ),
    );
  }, []);

  useEffect(() => {
    if (foundAccountData && foundPolicyData) {
      const mergedData = mergeByAccountNumberAndSort(
        foundAccountData,
        foundPolicyData,
      );

      setTableData(organizeTableData(mergedData, foundUserName));
    }
  }, [foundAccountData, foundPolicyData]);

  const onContinue: Function = () => {
    const selectedRadios = document.querySelectorAll(
      'input[type="radio"]:checked',
    );

    if (
      selectedRadios.length > 0 &&
      clickedRowIndex !== undefined &&
      foundAccountData
    ) {
      sessionStorage.setItem(
        'returnedAccountAttributesCollection',
        JSON.stringify([foundAccountData[clickedRowIndex]]),
      );
      sessionStorage.setItem(
        'returnedGivenName',
        foundAccountData[clickedRowIndex].first_name,
      );
      sessionStorage.removeItem('returnedPolicyAttributesCollection');
      setMultipleAccountsAssociatedView(false);
      setVerificationView(true);
    } else setError(true);
  };

  return (
    <MultipleAccountsAssociated
      tableColumns={tableColumns}
      tableData={tableData}
      onContinue={onContinue}
      setClickedRowIndex={setClickedRowIndex}
      error={error}
      setError={setError}
    />
  );
};

export default MultipleAccountsAssociatedDataContainer;
