import React, { useState } from 'react';
import { SAButton, SAIcons, SAText } from '@saux/design-system-react';
import { SAInput } from '../../../util/SAUXDS/input/SAInput';
import { SASelect } from '../../../util/SAUXDS/select/SASelect';
import {
  ButtonHolder,
  TextHolder,
  NotWideInput,
  WideInput,
  TextSpacer,
  MoreSpacedRow,
  MoreSpacedRowAlignStart,
  TextSpacerRight,
  SmallerTextSpacer,
  TextSpacerRightLarger,
  WiderInput,
  Card,
  GreenWrapper,
  CheckWrapper,
  WideTextSpacerRight,
} from '../SignUpModalStyles';
import { ReactComponent as SpinnerLight } from '../../../assets/images/Spinner-Light.svg';
import { ReactComponent as GreenCheck } from '../../../assets/images/GreenCheck.svg';
import { ReactComponent as ClearAlert } from '../../../assets/images/ClearAlert.svg';
import useIsResponsive from '../../../util/useIsResponsive';
import { formatTitleCase } from '../../../util';
import { SignedUpUserInfo } from '../SignUpModal';
import { securityQuestionsOptions } from '../setQuestionsModal/SetQuestionsModal';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

interface ConfirmUserInfoModalProps {
  displayFirstName: string | undefined;
  foundUserName: string | undefined;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  setSignedUpUserInfo: Function;
  registerUser: Function;
  loading: boolean;
  invalidUsername: boolean;
  takenUserName: boolean;
  passwordError: boolean;
  setPasswordError: Function;
  setTakenUserName: Function;
  setInvalidUsername: Function;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  confirmedPassword: string;
  setConfirmedPassword: Function;
  accountType: string;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
}

interface ResponsiveViewProps {
  foundUserName: string | undefined;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  setSignedUpUserInfo: Function;
  registerUser: Function;
  loading: boolean;
  invalidUsername: boolean;
  takenUserName: boolean;
  passwordError: boolean;
  setPasswordError: Function;
  setTakenUserName: Function;
  setInvalidUsername: Function;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  confirmedPassword: string;
  setConfirmedPassword: Function;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
  accountType: string;
}

interface FullSizeViewProps extends ResponsiveViewProps {
  displayFirstName: string | undefined;
}

const FullSizeView: Function = ({
  accountType,
  displayFirstName,
  foundUserName,
  signedUpUserInfo,
  setSignedUpUserInfo,
  registerUser,
  loading,
  invalidUsername,
  takenUserName,
  passwordError,
  setPasswordError,
  setTakenUserName,
  setInvalidUsername,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  confirmedPassword,
  setConfirmedPassword,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
}: FullSizeViewProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <SAText
        text={`Welcome to State Auto${
          displayFirstName ? `, ${displayFirstName}` : ''
        }!`}
        type="heading-1"
        alignment="center"
      />
      <Card>
        <TextSpacer>
          <SAText
            text="Lastly, confirm your personal information before finishing the
    registration."
            type="standard"
            weight="bold"
            alignment="left"
          />
        </TextSpacer>
        <TextSpacer>
          <SAText
            text="Personal Information"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <SAText text="Name" type="heading-3" alignment="left" />
        <MoreSpacedRow>
          <WideInput>
            <SAInput
              id="sign-up-confirm-first-name-input"
              hint="First Name"
              type="text"
              fullWidth
              defaultValue={formatTitleCase(signedUpUserInfo?.firstName) ?? ''}
              disabled
            />
          </WideInput>
          <NotWideInput>
            <SAInput
              id="sign-up-confirm-middle-name-input"
              type="text"
              hint="Middle Name/Initial"
              fullWidth
              disabled
            />
          </NotWideInput>
          <WideInput>
            <SAInput
              id="sign-up-confirm-last-name-input"
              type="text"
              hint="Last Name"
              fullWidth
              defaultValue={formatTitleCase(signedUpUserInfo?.lastName) ?? ''}
              disabled
            />
          </WideInput>
        </MoreSpacedRow>
        {accountType === 'Personal' && (
          <>
            <SAText text="Date of Birth" type="heading-3" alignment="left" />
            <MoreSpacedRow>
              <WideInput>
                <SAInput
                  id="sign-up-confirm-dateofbirth-input"
                  type="date"
                  fullWidth
                  disabled
                  defaultValue={signedUpUserInfo?.dateOfBirth}
                />
              </WideInput>
            </MoreSpacedRow>
          </>
        )}
        <SAText text="Your Account Number" type="heading-3" alignment="left" />
        <MoreSpacedRow>
          <WideInput>
            <SAInput
              id="sign-up-confirm-account-number-input"
              type="text"
              test-attr="sign-up-confirm-account-number-input"
              fullWidth
              defaultValue={signedUpUserInfo?.accountNumber}
              disabled
            />
          </WideInput>
        </MoreSpacedRow>
        <SmallerTextSpacer>
          <SAText
            text="Note: If any information appearing on this screen is incorrect, please contact your agent to correct."
            type="small"
            colorVariant="alert"
            alignment="left"
          />
        </SmallerTextSpacer>
        <TextSpacer />
        <TextSpacer>
          <SAText
            text="Username and Password"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <MoreSpacedRowAlignStart>
          <TextSpacerRight>
            <SAText text="Username" type="heading-3" alignment="left" />
          </TextSpacerRight>
          {!invalidUsername && !takenUserName && (
            <WideInput>
              <SAInput
                id="sign-up-user-name-input"
                test-attr="sign-up-user-name-input"
                type="text"
                fullWidth
                defaultValue={signedUpUserInfo?.userName ?? foundUserName}
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    userName: evt.target.value.replace(/\s/g, ''),
                  });
                }}
              />
            </WideInput>
          )}
          {invalidUsername && !takenUserName && (
            <WideInput>
              <SAInput
                id="sign-up-user-name-input"
                test-attr="sign-up-invalid-user-name-input"
                type="text"
                fullWidth
                error
                label="Invalid Username"
                defaultValue={signedUpUserInfo?.userName ?? foundUserName}
                onClick={() => setInvalidUsername(false)}
              />
            </WideInput>
          )}
          {!invalidUsername && takenUserName && (
            <WideInput>
              <SAInput
                id="sign-up-user-name-input"
                test-attr="sign-up-taken-user-name-input"
                type="text"
                fullWidth
                error
                label="Username Already Taken"
                defaultValue={signedUpUserInfo?.userName ?? foundUserName}
                onClick={() => setTakenUserName(false)}
              />
            </WideInput>
          )}
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRight>
            <SAText text="Password" type="heading-3" alignment="left" />
          </TextSpacerRight>
          {passwordError ? (
            <WideInput>
              <SAInput
                data-private
                id="sign-up-password-input-requirements-not-met"
                test-attr="sign-up-password-input-requirements-not-met"
                type="password"
                label="Password does not match requirements"
                error
                fullWidth
                onClick={() => setPasswordError(false)}
                defaultValue={signedUpUserInfo?.password}
              />
            </WideInput>
          ) : (
            <WideInput>
              <SAInput
                data-private
                id="sign-up-password-input"
                test-attr="confirm-password-input"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    password: evt.target.value.replace(/\s/g, ''),
                  });
                }}
                defaultValue={signedUpUserInfo?.password}
                icon={SAIcons.eye}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
            </WideInput>
          )}
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRight />
          {passwordsDoNotMatch ? (
            <WideInput>
              <SAInput
                data-private
                id="sign-up-password-input-confirm-pass-error"
                test-attr="sign-up-password-input-confirm-pass-error"
                label="Passwords must match"
                type={showPassword ? 'text' : 'password'}
                value={confirmedPassword}
                fullWidth
                error
                onClick={() => {
                  setPasswordsDoNotMatch(false);
                }}
                icon={SAIcons.eye}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
            </WideInput>
          ) : (
            <WideInput>
              <SAInput
                data-private
                id="sign-up-password-input-confirm-pass"
                test-attr="sign-up-password-input-confirm-pass"
                label="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setConfirmedPassword(evt.target.value.replace(/\s/g, ''));
                }}
                defaultValue={confirmedPassword}
                icon={SAIcons.eye}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
            </WideInput>
          )}
        </MoreSpacedRowAlignStart>
        <TextHolder>
          <SAText
            text="Password must be at least 12 characters, not include personal/account information or spaces, and include all 4 of the following characteristics:"
            type="small"
            alignment="center"
          />
          {!passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasNumber && (
            <GreenWrapper>
              <SAText text="3) Number" type="small" alignment="center" />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasNumber && (
            <GreenWrapper>
              <SAText
                text="3) Number"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
        </TextHolder>
        <TextSpacer>
          <SAText
            text="Security Questions"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Question #1" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SASelect
              fullWidth
              id="sign-up-security-question-1-choice"
              test-attr="sign-up-security-question-1-choice"
              options={securityQuestionsOptions}
              onChange={(evt: { currentTarget: { value: string } }) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  firstSecurityQuestion: evt.currentTarget.value,
                });
              }}
              defaultValue={signedUpUserInfo?.firstSecurityQuestion}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Answer #1" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SAInput
              data-private
              test-attr="sign-up-security-question-1-answer"
              id="sign-up-security-question-1-input"
              type="text"
              fullWidth
              onChange={(evt) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  firstSecurityQuestionAnswer: evt.target.value,
                });
              }}
              defaultValue={signedUpUserInfo?.firstSecurityQuestionAnswer}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Question #2" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SASelect
              fullWidth
              id="sign-up-security-question-2-choice"
              test-attr="sign-up-security-question-2-choice"
              options={securityQuestionsOptions}
              onChange={(evt: { currentTarget: { value: string } }) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  secondSecurityQuestion: evt.currentTarget.value,
                });
              }}
              defaultValue={signedUpUserInfo?.secondSecurityQuestion}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Answer #2" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SAInput
              data-private
              id="sign-up-security-question-2-input"
              test-attr="sign-up-security-question-2-answer"
              type="text"
              fullWidth
              onChange={(evt) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  secondSecurityQuestionAnswer: evt.target.value,
                });
              }}
              defaultValue={signedUpUserInfo?.secondSecurityQuestionAnswer}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Question #3" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SASelect
              fullWidth
              id="sign-up-security-question-3-choice"
              test-attr="sign-up-security-question-3-choice"
              options={securityQuestionsOptions}
              onChange={(evt: { currentTarget: { value: string } }) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  thirdSecurityQuestion: evt.currentTarget.value,
                });
              }}
              defaultValue={signedUpUserInfo?.thirdSecurityQuestion}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <MoreSpacedRowAlignStart>
          <TextSpacerRightLarger>
            <SAText text="Answer #3" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <WiderInput>
            <SAInput
              data-private
              id="sign-up-security-question-3-input"
              test-attr="sign-up-security-question-3-answer"
              type="text"
              fullWidth
              onChange={(evt) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  thirdSecurityQuestionAnswer: evt.target.value,
                });
              }}
              defaultValue={signedUpUserInfo?.thirdSecurityQuestionAnswer}
            />
          </WiderInput>
        </MoreSpacedRowAlignStart>
        <TextSpacer />
        <ButtonHolder>
          {!loading && (
            <SAButton
              test-attr="sign-up-continue-button"
              onClick={() => {
                if (confirmedPassword !== signedUpUserInfo?.password) {
                  setPasswordsDoNotMatch(true);
                } else registerUser();
                simpleGTMDataLayer({
                  event: `Registration-ConfirmInfoPageContinueButton-click`,
                  event_action: 'Button Click',
                  event_category: 'Registration',
                  event_label:
                    'Registration Confirm Info Page Continue Button Click',
                  event_category_snowplow: 'Snowplow Registration',
                });
              }}
              label="CONTINUE"
              variant="primary-large"
              fullWidth
            />
          )}
          {loading && (
            <SAButton label="CONTINUE" variant="primary-large" fullWidth>
              <SpinnerLight />
            </SAButton>
          )}
        </ButtonHolder>
      </Card>
    </>
  );
};

const ResponsiveView: Function = ({
  foundUserName,
  accountType,
  signedUpUserInfo,
  setSignedUpUserInfo,
  registerUser,
  loading,
  invalidUsername,
  takenUserName,
  passwordError,
  setPasswordError,
  setTakenUserName,
  setInvalidUsername,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  confirmedPassword,
  setConfirmedPassword,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
}: ResponsiveViewProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Card>
        <TextSpacer>
          <SAText
            text="Lastly, confirm your personal information before finishing the
    registration."
            type="standard"
            weight="bold"
            alignment="left"
          />
        </TextSpacer>
        <TextSpacer>
          <SAText
            text="Personal Information"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <SAText text="Name" type="heading-3" alignment="left" />
        <MoreSpacedRow>
          <SAInput
            id="sign-up-confirm-first-name-input"
            hint="First Name"
            type="text"
            defaultValue={formatTitleCase(signedUpUserInfo?.firstName) ?? ''}
            fullWidth
            disabled
          />
          <SAInput
            id="sign-up-confirm-middle-name-input"
            type="text"
            hint="Middle Name/Initial"
            fullWidth
            disabled
          />
          <SAInput
            id="sign-up-confirm-last-name-input"
            type="text"
            hint="Last Name"
            fullWidth
            defaultValue={formatTitleCase(signedUpUserInfo?.lastName) ?? ''}
            disabled
          />
        </MoreSpacedRow>
        <TextSpacer />
        {accountType === 'Personal' && (
          <>
            <SAText text="Date of Birth" type="heading-3" alignment="left" />
            <MoreSpacedRow>
              <SAInput
                id="sign-up-confirm-dateofbirth-input"
                type="date"
                fullWidth
                disabled
                defaultValue={signedUpUserInfo?.dateOfBirth}
              />
            </MoreSpacedRow>
          </>
        )}
        <TextSpacer />
        <SAText text="Your Account Number" type="heading-3" alignment="left" />
        <MoreSpacedRow>
          <SAInput
            id="sign-up-confirm-account-number-input"
            test-attr="sign-up-confirm-account-number-input"
            type="text"
            fullWidth
            defaultValue={signedUpUserInfo?.accountNumber}
            disabled
          />
        </MoreSpacedRow>
        <SmallerTextSpacer>
          <SAText
            text="Note: If any information appearing on this screen is incorrect, please contact your agent to correct."
            type="small"
            colorVariant="alert"
            alignment="left"
          />
        </SmallerTextSpacer>
        <TextSpacer />
        <TextSpacer>
          <SAText
            text="Username and Password"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <MoreSpacedRow>
          <TextSpacerRight>
            <SAText text="Username" type="heading-3" alignment="left" />
          </TextSpacerRight>
          {!invalidUsername && !takenUserName && (
            <SAInput
              id="sign-up-user-name-input"
              test-attr="sign-up-user-name-input"
              type="text"
              fullWidth
              defaultValue={signedUpUserInfo?.userName ?? foundUserName}
              onKeyDown={(evt) => {
                if (evt.code === 'Space') evt.preventDefault();
              }}
              onChange={(evt) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  userName: evt.target.value.replace(/\s/g, ''),
                });
              }}
            />
          )}
          {invalidUsername && !takenUserName && (
            <SAInput
              id="sign-up-user-name-input"
              test-attr="sign-up-invalid-user-name-input"
              type="text"
              fullWidth
              error
              hint="Invalid Username"
              defaultValue={signedUpUserInfo?.userName ?? foundUserName}
              onClick={() => setInvalidUsername(false)}
            />
          )}
          {!invalidUsername && takenUserName && (
            <SAInput
              id="sign-up-user-name-input"
              test-attr="sign-up-taken-user-name-input"
              type="text"
              fullWidth
              error
              hint="Username Already Taken"
              defaultValue={signedUpUserInfo?.userName ?? foundUserName}
              onClick={() => setTakenUserName(false)}
            />
          )}
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRight>
            <SAText text="Password" type="heading-3" alignment="left" />
          </TextSpacerRight>
          {passwordError ? (
            <SAInput
              data-private
              id="sign-up-password-input-requirements-not-met"
              test-attr="sign-up-password-input-requirements-not-met-responsive"
              type="password"
              fullWidth
              hint="Password does not match requirements"
              error
              defaultValue={signedUpUserInfo?.password}
              onClick={() => setPasswordError(false)}
            />
          ) : (
            <SAInput
              data-private
              id="sign-up-password-input"
              test-attr="confirm-password-input-responsive"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              defaultValue={signedUpUserInfo?.password}
              onKeyDown={(evt) => {
                if (evt.code === 'Space') evt.preventDefault();
              }}
              onChange={(evt) => {
                setSignedUpUserInfo({
                  ...signedUpUserInfo,
                  password: evt.target.value.replace(/\s/g, ''),
                });
              }}
              icon={SAIcons.eye}
              onClickIcon={() => setShowPassword(!showPassword)}
            />
          )}
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <WideTextSpacerRight>
            <SAText text="Confirm Password" type="heading-3" alignment="left" />
          </WideTextSpacerRight>
          {passwordsDoNotMatch ? (
            <SAInput
              data-private
              id="sign-up-password-input-confirm-pass-error-responsive"
              test-attr="sign-up-password-input-confirm-pass-error-responsive"
              hint="Passwords must match"
              type={showPassword ? 'text' : 'password'}
              value={confirmedPassword}
              fullWidth
              error
              onClick={() => {
                setPasswordsDoNotMatch(false);
              }}
              icon={SAIcons.eye}
              onClickIcon={() => setShowPassword(!showPassword)}
            />
          ) : (
            <SAInput
              data-private
              id="sign-up-password-input-confirm-pass-responsive"
              test-attr="sign-up-password-input-confirm-pass-responsive"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              onKeyDown={(evt) => {
                if (evt.code === 'Space') evt.preventDefault();
              }}
              onChange={(evt) => {
                setConfirmedPassword(evt.target.value.replace(/\s/g, ''));
              }}
              defaultValue={confirmedPassword}
              icon={SAIcons.eye}
              onClickIcon={() => setShowPassword(!showPassword)}
            />
          )}
        </MoreSpacedRow>
        <TextSpacer />
        <TextHolder>
          <SAText
            text="Password must be at least 12 characters, not include personal/account information or spaces, and include all 4 of the following characteristics:"
            type="small"
            alignment="center"
          />
          {!passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasNumber && (
            <GreenWrapper>
              <SAText text="3) Number" type="small" alignment="center" />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasNumber && (
            <GreenWrapper>
              <SAText
                text="3) Number"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
        </TextHolder>
        <TextSpacer>
          <SAText
            text="Security Questions"
            type="heading-2"
            colorVariant="primary"
            alignment="left"
          />
        </TextSpacer>
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Question #1" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SASelect
            fullWidth
            id="sign-up-security-question-1-choice"
            test-attr="sign-up-security-question-1-choice"
            options={securityQuestionsOptions}
            onChange={(evt: { currentTarget: { value: string } }) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                firstSecurityQuestion: evt.currentTarget.value,
              });
            }}
            defaultValue={signedUpUserInfo?.firstSecurityQuestion}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Answer #1" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SAInput
            data-private
            id="sign-up-security-question-1-input"
            test-attr="sign-up-security-question-1-answer"
            type="text"
            fullWidth
            onChange={(evt) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                firstSecurityQuestionAnswer: evt.target.value,
              });
            }}
            defaultValue={signedUpUserInfo?.firstSecurityQuestionAnswer}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Question #2" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SASelect
            fullWidth
            id="sign-up-security-question-2-choice"
            test-attr="sign-up-security-question-2-choice"
            options={securityQuestionsOptions}
            onChange={(evt: { currentTarget: { value: string } }) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                secondSecurityQuestion: evt.currentTarget.value,
              });
            }}
            defaultValue={signedUpUserInfo?.secondSecurityQuestion}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Answer #2" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SAInput
            data-private
            id="sign-up-security-question-2-input"
            test-attr="sign-up-security-question-2-answer"
            type="text"
            fullWidth
            onChange={(evt) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                secondSecurityQuestionAnswer: evt.target.value,
              });
            }}
            defaultValue={signedUpUserInfo?.secondSecurityQuestionAnswer}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Question #3" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SASelect
            fullWidth
            id="sign-up-security-question-3-choice"
            test-attr="sign-up-security-question-3-choice"
            options={securityQuestionsOptions}
            onChange={(evt: { currentTarget: { value: string } }) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                thirdSecurityQuestion: evt.currentTarget.value,
              });
            }}
            defaultValue={signedUpUserInfo?.thirdSecurityQuestion}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <MoreSpacedRow>
          <TextSpacerRightLarger>
            <SAText text="Answer #3" type="heading-3" alignment="left" />
          </TextSpacerRightLarger>
          <SAInput
            data-private
            id="sign-up-security-question-3-input"
            test-attr="sign-up-security-question-3-answer"
            type="text"
            fullWidth
            onChange={(evt) => {
              setSignedUpUserInfo({
                ...signedUpUserInfo,
                thirdSecurityQuestionAnswer: evt.target.value,
              });
            }}
            defaultValue={signedUpUserInfo?.thirdSecurityQuestionAnswer}
          />
        </MoreSpacedRow>
        <TextSpacer />
        <ButtonHolder>
          {!loading && (
            <SAButton
              test-attr="sign-up-continue-button"
              onClick={() => {
                if (confirmedPassword !== signedUpUserInfo?.password) {
                  setPasswordsDoNotMatch(true);
                } else registerUser();
                simpleGTMDataLayer({
                  event: `Registration-ConfirmInfoPageContinueButton-click`,
                  event_action: 'Button Click',
                  event_category: 'Registration',
                  event_label:
                    'Registration Confirm Info Page Continue Button Click',
                  event_category_snowplow: 'Snowplow Registration',
                });
              }}
              label="CONTINUE"
              variant="primary-large"
              fullWidth
            />
          )}
          {loading && (
            <SAButton label="CONTINUE" variant="primary-large" fullWidth>
              <SpinnerLight />
            </SAButton>
          )}
        </ButtonHolder>
      </Card>
    </>
  );
};

export const ConfirmUserInfoModal = ({
  loading,
  displayFirstName,
  foundUserName,
  signedUpUserInfo,
  setSignedUpUserInfo,
  registerUser,
  invalidUsername,
  takenUserName,
  passwordError,
  setPasswordError,
  setTakenUserName,
  setInvalidUsername,
  passwordHasCapital,
  passwordHasNumber,
  accountType,
  passwordHasLowercase,
  passwordHasSpecialChar,
  confirmedPassword,
  setConfirmedPassword,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
}: ConfirmUserInfoModalProps) => {
  const isResponsive = useIsResponsive();

  return (
    <>
      {!isResponsive && (
        <FullSizeView
          displayFirstName={displayFirstName}
          foundUserName={foundUserName}
          signedUpUserInfo={signedUpUserInfo}
          setSignedUpUserInfo={setSignedUpUserInfo}
          registerUser={registerUser}
          loading={loading}
          invalidUsername={invalidUsername}
          accountType={accountType}
          takenUserName={takenUserName}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setTakenUserName={setTakenUserName}
          setInvalidUsername={setInvalidUsername}
          passwordHasCapital={passwordHasCapital}
          passwordHasNumber={passwordHasNumber}
          passwordHasLowercase={passwordHasLowercase}
          passwordHasSpecialChar={passwordHasSpecialChar}
          confirmedPassword={confirmedPassword}
          setConfirmedPassword={setConfirmedPassword}
          passwordsDoNotMatch={passwordsDoNotMatch}
          setPasswordsDoNotMatch={setPasswordsDoNotMatch}
        />
      )}
      {isResponsive && (
        <ResponsiveView
          foundUserName={foundUserName}
          signedUpUserInfo={signedUpUserInfo}
          setSignedUpUserInfo={setSignedUpUserInfo}
          registerUser={registerUser}
          accountType={accountType}
          loading={loading}
          invalidUsername={invalidUsername}
          takenUserName={takenUserName}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          setTakenUserName={setTakenUserName}
          setInvalidUsername={setInvalidUsername}
          passwordHasCapital={passwordHasCapital}
          passwordHasNumber={passwordHasNumber}
          passwordHasLowercase={passwordHasLowercase}
          passwordHasSpecialChar={passwordHasSpecialChar}
          confirmedPassword={confirmedPassword}
          setConfirmedPassword={setConfirmedPassword}
          passwordsDoNotMatch={passwordsDoNotMatch}
          setPasswordsDoNotMatch={setPasswordsDoNotMatch}
        />
      )}
    </>
  );
};

export default ConfirmUserInfoModal;
