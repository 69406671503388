import React, { useState } from 'react';
import { SAText } from '@saux/design-system-react';
import { QuestionSet } from './QuestionSet';
import { TextSpacer } from './Common';
import { securityQuestionsOptions } from '../signUpModal/setQuestionsModal/SetQuestionsModal';

interface RegistrationQuestionsProp {
  handleSelectOption: Function,
  handleAnswerUpdate: Function,
  selectedQuestions: Array<any>
};


export const RegistrationQuestions: Function = ({handleSelectOption, handleAnswerUpdate, selectedQuestions}: RegistrationQuestionsProp) => {
  
  const customFilterSelected=(filterQuestion: number)=>{
    const tmp = securityQuestionsOptions.filter((itmQuestion, indexSrc)=>{
      if (indexSrc > 0) {
        const tmpFind = selectedQuestions.find((itm) => {
          return (itm.valueText === itmQuestion.value);
        });
        return !tmpFind || (Number(tmpFind.questionSetIndex) === Number(filterQuestion));
      }
      return true;
    });
    return tmp;
  };
  return (
    <>
      <TextSpacer>
        <SAText
          text="Security Questions"
          type="heading-2"
          colorVariant="primary"
          alignment="left"
        />
      </TextSpacer>
      <QuestionSet
        qsNumber="1"
        availableOptions={customFilterSelected(1)}
        handleQuestionSelect={handleSelectOption}
        handleAnswerUpdate={handleAnswerUpdate}
      />
      <QuestionSet
        qsNumber="2"
        availableOptions={customFilterSelected(2)}
        handleQuestionSelect={handleSelectOption}
        handleAnswerUpdate={handleAnswerUpdate}
      />
      <QuestionSet
        qsNumber="3"
        availableOptions={customFilterSelected(3)}
        handleQuestionSelect={handleSelectOption}
        handleAnswerUpdate={handleAnswerUpdate}
      />
    </>
  );
};
export default RegistrationQuestions;
