import React from 'react';
import {
  SAText,
  SARadioGroup,
  SARadio,
  SAButton,
} from '@saux/design-system-react';
import {
  ButtonHolder,
  Card,
  ErrorHolder,
  HeadingTextHolder,
  RadioWrapper,
} from '../accountTypeModal/AccountTypeStyles';
import { ReactComponent as SpinnerLight } from '../../assets/images/Spinner-Light.svg';
import { TimerHolder } from '../verificationCodeModal/VerificationCodeModalStyles';

interface VerificationCodeChoiceModalProps {
  verificationOptions: {
    phone?: string | null;
    email?: string | null;
    masked: string | null;
    account: string | null;
  }[];
  onContinue: Function;
  error: boolean;
  loading: boolean;
}

const VerificationCodeChoiceModal = ({
  verificationOptions,
  onContinue,
  error,
  loading,
}: VerificationCodeChoiceModalProps) => {
  return (
    <Card>
      <HeadingTextHolder>
        <SAText
          text="Choose a method to receive the verification code"
          type="heading-3"
          alignment="center"
        />
      </HeadingTextHolder>
      <RadioWrapper>
        <SARadioGroup id="contactType" variant="standard" direction="vertical">
          {verificationOptions?.map((contactInfo, index: number) => {
            const ID = `${contactInfo.masked}-${index}`;
            if (contactInfo && contactInfo.masked) {
              return (
                <SARadio
                  id={ID}
                  value={contactInfo.email ?? contactInfo.phone ?? ''}
                  type="radio"
                  label={
                    contactInfo.email
                      ? `Email: ${contactInfo.masked}`
                      : `Text: ${contactInfo.masked}`
                  }
                  variant="standard"
                  key={contactInfo.masked}
                />
              );
            }
            return <></>;
          })}
        </SARadioGroup>
      </RadioWrapper>
      <TimerHolder>
        <SAText
          text="By selecting one of the contact channels, you are providing a one-time authorization for us to send you an identification code. For mobile, message and data rates may apply."
          type="small"
          alignment="center"
        />
      </TimerHolder>
      <ButtonHolder>
        {loading ? (
          <SAButton label="CONTINUE" variant="primary-large" fullWidth>
            <SpinnerLight />
          </SAButton>
        ) : (
          <SAButton
            test-attr="verification-code-choice-continue"
            onClick={() => onContinue()}
            label="CONTINUE"
            variant="primary-large"
            fullWidth
          />
        )}
      </ButtonHolder>
      {error && (
        <ErrorHolder>
          <SAText colorVariant="alert" text="Please make a selection" />
        </ErrorHolder>
      )}
    </Card>
  );
};

export default VerificationCodeChoiceModal;
