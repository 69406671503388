/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
import React, { MutableRefObject, useState } from 'react';
import { SARadio } from '@saux/design-system-react';
import { AccountType } from './AccountType';

interface AccountTypeDataContainerProps {
  setInitialEmailCheckView: Function;
  setInitialAccountType: Function;
  accountType: MutableRefObject<string>;
}

export const radioClick = () => {
  return (
    <div>
      <SARadio
        value="Personal"
        type="radio"
        label="Personal"
        variant="standard"
        name="Personal"
      />
      <SARadio
        value="Business"
        type="radio"
        label="Business"
        variant="standard"
        name="Business"
      />
    </div>
  );
};

const AccountTypeDataContainer = ({
  setInitialAccountType,
  setInitialEmailCheckView,
  accountType,
}: AccountTypeDataContainerProps) => {
  const [error, setError] = useState<boolean>(false);

  const onContinue: Function = () => {
    const selectedRadios: any = document.querySelectorAll(
      'input[type="radio"]:checked',
    );

    if (selectedRadios.length > 0) {
      accountType.current = selectedRadios[0].value;
      setInitialAccountType(false);
      setInitialEmailCheckView(true);
    } else setError(true);
  };

  return (
    <AccountType onContinue={onContinue} error={error} setError={setError} />
  );
};

export default AccountTypeDataContainer;
