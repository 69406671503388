import React, { useState } from 'react';
import { SAButton, SAInput, SASelect, SAText } from '@saux/design-system-react';
import {
  ButtonHolder,
  StepText,
  InputsHolder,
  InputWrap,
  Card,
  SmallInputWrap,
} from '../SignUpModalStyles';
import useIsResponsive from '../../../util/useIsResponsive';
import { SignedUpUserInfo } from '../SignUpModal';

interface SetQuestionsModalProps {
  displayFirstName: string | undefined;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  setSignedUpUserInfo: Function;
  setShowSetQuestionsModal: Function;
  setShowConfirmUserInfoModal: Function;
}

// this needs to be typed as SASelectItem[], but the SASelectItem type is not exported from the DS afaik
export const securityQuestionsOptions: any[] = [
  {
    label: 'Select an option',
    value: 'Select an option',
  },
  {
    label: 'What is the name of the first street you lived on?',
    value: 'What is the name of the first street you lived on?',
  },
  {
    label: 'What is the name of your childhood best friend?',
    value: 'What is the name of your childhood best friend?',
  },
  {
    label: 'Who is your favorite author?',
    value: 'Who is your favorite author?',
  },
  {
    label: 'What was your favorite toy as a child?',
    value: 'What was your favorite toy as a child?',
  },
  {
    label: 'What was the name of your elementary school?',
    value: 'What was the name of your elementary school?',
  },
  {
    label: 'What is the name of your favorite sports team?',
    value: 'What is the name of your favorite sports team?',
  },
  {
    label: 'What was your favorite place to visit as a child?',
    value: 'What was your favorite place to visit as a child?',
  },
];

export const SetQuestionsModal = ({
  displayFirstName,
  signedUpUserInfo,
  setSignedUpUserInfo,
  setShowSetQuestionsModal,
  setShowConfirmUserInfoModal,
}: SetQuestionsModalProps) => {
  const [errorSectionOne, setErrorSectionOne] = useState<boolean>(false);
  const [errorSectionTwo, setErrorSectionTwo] = useState<boolean>(false);
  const [errorSectionThree, setErrorSectionThree] = useState<boolean>(false);
  const [showHintOne, setShowHintOne] = useState<boolean>(false);
  const [showHintTwo, setShowHintTwo] = useState<boolean>(false);
  const [showHintThree, setShowHintThree] = useState<boolean>(false);

  const onContinue: Function = () => {
    if (
      (signedUpUserInfo &&
        signedUpUserInfo.firstSecurityQuestion === 'Select an option') ||
      (signedUpUserInfo &&
        signedUpUserInfo.firstSecurityQuestionAnswer &&
        signedUpUserInfo.firstSecurityQuestionAnswer.length < 4)
    ) {
      setErrorSectionOne(true);
    }
    if (
      (signedUpUserInfo &&
        signedUpUserInfo.secondSecurityQuestion === 'Select an option') ||
      (signedUpUserInfo &&
        signedUpUserInfo.secondSecurityQuestionAnswer &&
        signedUpUserInfo.secondSecurityQuestionAnswer.length < 4)
    ) {
      setErrorSectionTwo(true);
    }
    if (
      (signedUpUserInfo &&
        signedUpUserInfo.thirdSecurityQuestion === 'Select an option') ||
      (signedUpUserInfo &&
        signedUpUserInfo.thirdSecurityQuestionAnswer &&
        signedUpUserInfo.thirdSecurityQuestionAnswer.length < 4)
    ) {
      setErrorSectionThree(true);
    }
    if (
      signedUpUserInfo &&
      signedUpUserInfo.firstSecurityQuestion !== 'Select an option' &&
      signedUpUserInfo.firstSecurityQuestionAnswer &&
      signedUpUserInfo.firstSecurityQuestionAnswer.length > 3 &&
      signedUpUserInfo.secondSecurityQuestion !== 'Select an option' &&
      signedUpUserInfo.secondSecurityQuestionAnswer &&
      signedUpUserInfo.secondSecurityQuestionAnswer.length > 3 &&
      signedUpUserInfo.thirdSecurityQuestion !== 'Select an option' &&
      signedUpUserInfo.thirdSecurityQuestionAnswer &&
      signedUpUserInfo.thirdSecurityQuestionAnswer.length > 3
    ) {
      setShowSetQuestionsModal(false);
      setShowConfirmUserInfoModal(true);
    }
  };
  const isResponsive = useIsResponsive();

  return (
    <>
      {!isResponsive && (
        <SAText
          text={`Welcome to State Auto${
            displayFirstName ? `, ${displayFirstName}` : ''
          }!`}
          type="heading-1"
          alignment="center"
        />
      )}
      <Card>
        <StepText>Step 2 of 2</StepText>
        <SAText
          text="Now, set up the security questions for future retrieval of account information."
          type="heading-3"
          alignment="center"
        />
        <InputsHolder>
          <InputWrap>
            <SmallInputWrap>
              <SASelect
                fullWidth
                label="Security Question #1"
                id="sign-up-security-question-1-choice"
                test-attr="sign-up-security-question-1-choice"
                options={
                  !signedUpUserInfo?.secondSecurityQuestion &&
                  !signedUpUserInfo?.thirdSecurityQuestion
                    ? securityQuestionsOptions
                    : securityQuestionsOptions.filter((option) => {
                        return (
                          signedUpUserInfo &&
                          signedUpUserInfo.secondSecurityQuestion !==
                            option.value &&
                          signedUpUserInfo.thirdSecurityQuestion !==
                            option.value
                        );
                      })
                }
                onChange={(evt: any) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    firstSecurityQuestion: evt.currentTarget.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo && signedUpUserInfo.firstSecurityQuestion
                    ? signedUpUserInfo.firstSecurityQuestion
                    : ''
                }
              />
            </SmallInputWrap>
            {!errorSectionOne && (
              <SAInput
                data-private
                id="sign-up-security-question-1-input"
                test-attr="sign-up-security-question-1-input"
                label="Answer #1"
                type="text"
                fullWidth
                hint={
                  showHintOne ? 'Answers must be at least 4 characters.' : ''
                }
                onFocus={() => setShowHintOne(true)}
                onBlur={() => setShowHintOne(false)}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    firstSecurityQuestionAnswer: evt.target.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.firstSecurityQuestionAnswer
                    ? signedUpUserInfo.firstSecurityQuestionAnswer
                    : ''
                }
              />
            )}
            {errorSectionOne && (
              <SAInput
                data-private
                error
                id="error-inputs"
                test-attr="sign-up-security-question-1-input-error"
                label="Answer #1"
                type="text"
                fullWidth
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.firstSecurityQuestionAnswer
                    ? signedUpUserInfo.firstSecurityQuestionAnswer
                    : ''
                }
                onClick={() => setErrorSectionOne(false)}
              />
            )}
          </InputWrap>
          <InputWrap>
            <SmallInputWrap>
              <SASelect
                fullWidth
                label="Security Question #2"
                id="sign-up-security-question-2-choice"
                test-attr="sign-up-security-question-2-choice"
                options={
                  !signedUpUserInfo?.firstSecurityQuestion &&
                  !signedUpUserInfo?.thirdSecurityQuestion
                    ? securityQuestionsOptions
                    : securityQuestionsOptions.filter((option) => {
                        return (
                          signedUpUserInfo &&
                          signedUpUserInfo.firstSecurityQuestion !==
                            option.value &&
                          signedUpUserInfo.thirdSecurityQuestion !==
                            option.value
                        );
                      })
                }
                onChange={(evt: any ) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    secondSecurityQuestion: evt.currentTarget?.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo && signedUpUserInfo.secondSecurityQuestion
                    ? signedUpUserInfo.secondSecurityQuestion
                    : ''
                }
              />
            </SmallInputWrap>
            {!errorSectionTwo && (
              <SAInput
                data-private
                id="sign-up-security-question-2-input"
                test-attr="sign-up-security-question-2-input"
                label="Answer #2"
                type="text"
                fullWidth
                hint={
                  showHintTwo ? 'Answers must be at least 4 characters.' : ''
                }
                onFocus={() => setShowHintTwo(true)}
                onBlur={() => setShowHintTwo(false)}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    secondSecurityQuestionAnswer: evt.target.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.secondSecurityQuestionAnswer
                    ? signedUpUserInfo.secondSecurityQuestionAnswer
                    : ''
                }
              />
            )}
            {errorSectionTwo && (
              <SAInput
                data-private
                error
                id="error-inputs"
                label="Answer #2"
                type="text"
                fullWidth
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.secondSecurityQuestionAnswer
                    ? signedUpUserInfo.secondSecurityQuestionAnswer
                    : ''
                }
                onClick={() => setErrorSectionTwo(false)}
              />
            )}
          </InputWrap>
          <InputWrap>
            <SmallInputWrap>
              <SASelect
                fullWidth
                label="Security Question #3"
                id="sign-up-security-question-3-choice"
                test-attr="sign-up-security-question-3-choice"
                options={
                  !signedUpUserInfo?.firstSecurityQuestion &&
                  !signedUpUserInfo?.secondSecurityQuestion
                    ? securityQuestionsOptions
                    : securityQuestionsOptions.filter((option) => {
                        return (
                          signedUpUserInfo &&
                          signedUpUserInfo.firstSecurityQuestion !==
                            option.value &&
                          signedUpUserInfo.secondSecurityQuestion !==
                            option.value
                        );
                      })
                }
                onChange={(evt: any) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    thirdSecurityQuestion: evt.currentTarget.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo && signedUpUserInfo.thirdSecurityQuestion
                    ? signedUpUserInfo.thirdSecurityQuestion
                    : ''
                }
              />
            </SmallInputWrap>
            {!errorSectionThree && (
              <SAInput
                data-private
                id="sign-up-security-question-3-input"
                test-attr="sign-up-security-question-3-input"
                label="Answer #3"
                type="text"
                fullWidth
                hint={
                  showHintThree ? 'Answers must be at least 4 characters.' : ''
                }
                onFocus={() => setShowHintThree(true)}
                onBlur={() => setShowHintThree(false)}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    thirdSecurityQuestionAnswer: evt.target.value,
                  });
                }}
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.thirdSecurityQuestionAnswer
                    ? signedUpUserInfo.thirdSecurityQuestionAnswer
                    : ''
                }
              />
            )}
            {errorSectionThree && (
              <SAInput
                data-private
                error
                id="error-inputs"
                label="Answer #3"
                type="text"
                fullWidth
                defaultValue={
                  signedUpUserInfo &&
                  signedUpUserInfo.thirdSecurityQuestionAnswer
                    ? signedUpUserInfo.thirdSecurityQuestionAnswer
                    : ''
                }
                onClick={() => setErrorSectionThree(false)}
              />
            )}
          </InputWrap>
        </InputsHolder>
        <ButtonHolder>
          <SAButton
            test-attr="continue-set-security-questions-screen"
            onClick={() => onContinue()}
            label="CONTINUE"
            variant="primary-large"
            fullWidth
          />
        </ButtonHolder>
      </Card>
    </>
  );
};

export default SetQuestionsModal;
