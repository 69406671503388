import React from 'react';
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        height: auto;
        padding: 20px;
        margin: auto auto 20px auto;
      }
      margin: 50px auto 50px auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      height: 500px;
      width: 725px;
      padding: ${theme?.size?.spacer?.large} 50px ${theme?.size?.spacer?.large} 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
      align-items: center;
      justify-content: space-around;
   `;
  }}
`;
export const UpperSpacedElement = styled.div`
  padding: 20px 0;
  margin: 0 0 0 auto;
`;
export const LowerSpacedElement = styled.div`
  padding: 20px 0;
`;
export default Card;