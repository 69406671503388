import { ICustomerRegistration, IRegisterUserResponse, IPersonalInformation, ILoginInformation, RegisterReducerEnum } from "../../data/RegistrationData";

export const TEXT_LOADING = 'Please wait while we direct you to the final stage of registration';
export const TEXT_ERROR_NOUSERINFO = 'Registration information could not be found.';
export const TEXT_ERROR_PHONE = 'Please contact Customer Service at 800-288-4425';
export const TEXT_ERROR_REGISTRATION = 'Your Customer Connect Registration cannot be completed at this time'
export const TEXT_ERROR_SRVDOWN = 'The system is currently down for maintenance and your Customer Connect Registration cannot be completed at this time.';
export const TEXT_ERROR_SRVDOWN_2 = 'Please attempt your registation at a later time';
export const TEXT_ERROR_EXPIRED = 'Registration Link Expired';
export const TEXT_COMPLETED = 'Thank you for registering!';
export const TEXT_COMPLETED_2 = 'You are being redirected to the State Auto Customer Connect Login page.';
export const ERROR_DOB = 'The date of birth entered does not match the date of birth on record.';
export const ERROR_PHONE = 'Please verify the phone number entered as it does not match our records.';
export const ERROR_USER_EXISTS = 'The username you have chosen already exists. Please provide a different username for this account.';
export const ERROR_ASSIST= 'Please contact State Auto Customer Service 800-288-4425 for assistance';
export const WARNING_COMPLETE_FIELDS = 'Please complete the required fields to continue with the Registration';
export enum Screen { 
  REGISTER,
  NO_USER_INFO,
  REGISTRATION_FAIL,
  SERVER_DOWN,
  DISABLED,
  EXPIRED,
  COMPLETED
 };

export const mapCustomerRegistrationResponse =
(serviceResponse: IRegisterUserResponse): ICustomerRegistration => {
  // Deconstruct object to get the properties and create an object of type IPersonalInformation
  const { firstName, lastName, email, phone, phoneType, email:username, businessType, account, guid } = serviceResponse;
  // Create Personal information from deconstructed data
  const personalInformation: IPersonalInformation = {firstName, lastName, email, phone, phoneType};
  const password = "";
  // create login information from deconstructed email as username
  const loginInformation: ILoginInformation = { username, password };
  return { personalInformation, loginInformation, businessType, account, guid } ;
};
export const buildServiceUpdateAction = (customerResponse: ICustomerRegistration) => {
  return {
    target: RegisterReducerEnum.ALL,
    customerResponse: customerResponse
  }
};