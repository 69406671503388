import { ICustomerRegistration, IPersonalInformation, RegisterReducerEnum} from "./RegistrationData";

export const reducerCustomerInformation = (stateCustomerInfo: ICustomerRegistration, action: any) => {
  let nextState: ICustomerRegistration = stateCustomerInfo;
  if (action.target === RegisterReducerEnum.ALL) {
    nextState = action.customerResponse;
  } else if (action.target === RegisterReducerEnum.PERSONAL) {
    const tmpPersonal:IPersonalInformation={
      ...stateCustomerInfo.personalInformation,
      [action.field]: action.value
    }
    nextState = {
      ...stateCustomerInfo,
      personalInformation: tmpPersonal
    }
  }
  return nextState;
};
export default reducerCustomerInformation;