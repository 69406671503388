/* eslint-disable no-param-reassign */
import React, { MutableRefObject, useEffect, useState } from 'react';
import axios from 'axios';
import getConfig, { Env } from '../../aws_exports';
import VerificationCodeChoiceModal from './VerificationCodeChoiceModal';
import maskContacts from '../../util/formatters/maskContent';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface VerificationCodeChoiceModalDCProps {
  setVerificationChoiceView: Function;
  setVerificationView: Function;
  selectedVerificationMethod: MutableRefObject<string>;
}

export const generateVerificationCode = async (
  accountNumber: string,
  contact: string,
  setVerificationChoiceView: Function,
  setVerificationView: Function,
  setLoading: Function,
) => {
  const data = {
    accountNumber,
    ...(contact?.includes('@') ? { email: contact } : { phone: contact }),
  };

  await axios({
    method: 'post',
    url: awsExports.gateway_endpoints.verificationCode,
    data,
  })
    .then(() => {
      setLoading(false);
      setVerificationChoiceView(false);
      setVerificationView(true);
    })
    .catch((error) => {
      return console.log(error);
    });
};

const VerificationCodeChoiceModalDataContainer = ({
  setVerificationChoiceView,
  setVerificationView,
  selectedVerificationMethod,
}: VerificationCodeChoiceModalDCProps) => {
  const savedAccountNumber = JSON.parse(
    sessionStorage.getItem('returnedAccountAttributesCollection') ??
      '[{"account_number":null}]',
  )[0].account_number;
  const [verificationOptions, setVerificationOptions] = useState<Array<any>>([
    {
      email: sessionStorage.getItem('inputtedEmail'),
      masked: maskContacts(sessionStorage.getItem('inputtedEmail') ?? null),
    },
  ]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      JSON.parse(
        sessionStorage.getItem('returnedAccountAttributesCollection') ?? '""',
      )
    ) {
      const accountInfo = JSON.parse(
        sessionStorage.getItem('returnedAccountAttributesCollection') as string,
      );

      accountInfo?.forEach(
        (collection: {
          cell_phone: string | null;
          account_number: string | null;
        }) => {
          setVerificationOptions([
            ...verificationOptions,
            {
              phone: collection?.cell_phone ?? null,
              masked: maskContacts(collection?.cell_phone ?? null),
            },
          ]);
        },
      );
    }
  }, []);

  const onContinue: Function = () => {
    setLoading(true);
    const selectedRadio = document.querySelectorAll(
      'input[type="radio"]:checked',
    );

    selectedVerificationMethod.current = (
      selectedRadio[0] as HTMLInputElement
    ).value;

    if (selectedRadio.length > 0) {
      generateVerificationCode(
        savedAccountNumber,
        (selectedRadio[0] as HTMLInputElement).value,
        setVerificationChoiceView,
        setVerificationView,
        setLoading,
      );
    } else setError(true);
  };

  return (
    <VerificationCodeChoiceModal
      verificationOptions={verificationOptions}
      onContinue={onContinue}
      error={error}
      loading={loading}
    />
  );
};

export default VerificationCodeChoiceModalDataContainer;
