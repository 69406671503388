import React from 'react';
import {
  SAButton,
  SATable,
  SATableColumn,
  SAText,
} from '@saux/design-system-react';
import {
  ButtonHolder,
  HeadingTextHolder,
  LowerTextHolder,
  Card,
  TableWrapper,
} from './AccountAlreadyRegisteredStyles';
import getConfig, { Env } from '../../aws_exports';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface AccountAlreadyRegisteredProps {
  tableColumns: SATableColumn[];
  tableData: any[][];
}

export const AccountAlreadyRegistered = ({
  tableColumns,
  tableData,
}: AccountAlreadyRegisteredProps) => {
  return (
    <Card>
      <HeadingTextHolder>
        <SAText
          text={
            tableData && tableData.length > 1
              ? 'We have found accounts already associated with your email.'
              : 'We have found an account already associated with your email.'
          }
          type="heading-3"
          alignment="center"
        />
      </HeadingTextHolder>
      <TableWrapper>
        <SATable
          data={tableData}
          columns={tableColumns}
          variant="table-to-listview"
        />
      </TableWrapper>
      <LowerTextHolder>
        <div>
          <SAText
            text="Return to the login page and sign in with the existing username."
            type="standard"
            weight="normal"
            alignment="center"
          />
        </div>
        <SAText
          text="(If you have forgotten your username, you can retrieve it via “Forgot Username” on the login page)"
          type="small"
          weight="normal"
          alignment="center"
        />
      </LowerTextHolder>
      <ButtonHolder>
        <SAButton
          test-attr="return-to-login-already-registered"
          onClick={() => {
            window.open(awsExports.endpoint.customer360, '_self');
            simpleGTMDataLayer({
              event: 'Registration-AccountAlreadyRegisteredBackToLogin-click',
              event_action: 'Return To Login Click',
              event_category: 'Registration',
              event_label:
                'Registration Account Already Registered Back To Login Click',
              event_category_snowplow: 'Snowplow Registration',
            });
          }}
          label="RETURN TO LOGIN PAGE"
          variant="primary-large"
          fullWidth
        />
      </ButtonHolder>
    </Card>
  );
};

export default AccountAlreadyRegistered;
