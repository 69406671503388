import React, { useEffect, useState } from 'react';
import { SAButton, SAIcons, SAInput, SAText } from '@saux/design-system-react';
import {
  ButtonHolder,
  Card,
  StepText,
  TextHolder,
  InputsHolder,
  InputWrap,
  CheckWrapper,
  GreenWrapper,
} from '../SignUpModalStyles';
import useIsResponsive from '../../../util/useIsResponsive';
import { ReactComponent as SpinnerLight } from '../../../assets/images/Spinner-Light.svg';
import { ReactComponent as GreenCheck } from '../../../assets/images/GreenCheck.svg';
import { ReactComponent as ClearAlert } from '../../../assets/images/ClearAlert.svg';
import { SignedUpUserInfo } from '../SignUpModal';

interface SetPasswordModalProps {
  displayFirstName: string | undefined;
  foundUserName: string | undefined;
  signedUpUserInfo: SignedUpUserInfo | undefined;
  setSignedUpUserInfo: Function;
  checkUsernameAndPassword: Function;
  setTakenUserName: Function;
  takenUserName: boolean;
  loading: boolean;
  error: boolean;
  setError: Function;
  invalidUsername: boolean;
  setInvalidUsername: Function;
  passwordHasCapital: boolean;
  passwordHasNumber: boolean;
  passwordHasLowercase: boolean;
  passwordHasSpecialChar: boolean;
  confirmedPassword: string;
  setConfirmedPassword: Function;
  passwordsDoNotMatch: boolean;
  setPasswordsDoNotMatch: Function;
  showEmailAsDefaultUsername: boolean;
}

export const SetPasswordModal = ({
  displayFirstName,
  foundUserName,
  signedUpUserInfo,
  setSignedUpUserInfo,
  checkUsernameAndPassword,
  setTakenUserName,
  takenUserName,
  loading,
  error,
  setError,
  invalidUsername,
  setInvalidUsername,
  passwordHasCapital,
  passwordHasNumber,
  passwordHasLowercase,
  passwordHasSpecialChar,
  confirmedPassword,
  setConfirmedPassword,
  passwordsDoNotMatch,
  setPasswordsDoNotMatch,
  showEmailAsDefaultUsername,
}: SetPasswordModalProps) => {
  const isResponsive = useIsResponsive();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (signedUpUserInfo?.password === confirmedPassword) {
      setPasswordsDoNotMatch(false);
    }
  }, [signedUpUserInfo?.password, confirmedPassword]);

  return (
    <>
      {!isResponsive && (
        <SAText
          text={`Welcome to State Auto${
            displayFirstName ? `, ${displayFirstName}` : ''
          }!`}
          type="heading-1"
          alignment="center"
        />
      )}
      <Card>
        <StepText>Step 1 of 2</StepText>
        <SAText
          text={
            showEmailAsDefaultUsername
              ? 'Now set up a password for your account. By default, the username is your email.'
              : 'Now set up a username and password for your account.'
          }
          type="heading-3"
          alignment="center"
        />
        <InputsHolder>
          <InputWrap>
            {!takenUserName && !invalidUsername && (
              <SAInput
                id="sign-up-user-name-input"
                label="Username"
                type="text"
                fullWidth
                defaultValue={
                  signedUpUserInfo?.userName ??
                  (showEmailAsDefaultUsername ? foundUserName : '')
                }
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    userName: evt.target.value.replace(/\s/g, ''),
                  });
                }}
              />
            )}
            {invalidUsername && (
              <SAInput
                error
                id="sign-up-user-name-input"
                test-attr="sign-up-user-name-input"
                label="Invalid Username"
                type="text"
                fullWidth
                defaultValue={signedUpUserInfo?.userName}
                onClick={() => setInvalidUsername(false)}
              />
            )}
            {takenUserName && (
              <SAInput
                error
                id="sign-up-user-name-input"
                test-attr="sign-up-user-name-input"
                label="Username Already Taken"
                type="text"
                fullWidth
                defaultValue={signedUpUserInfo?.userName}
                onClick={() => setTakenUserName(false)}
              />
            )}
          </InputWrap>
          <InputWrap>
            {!error && (
              <SAInput
                data-private
                id="sign-up-password-input"
                test-attr="sign-up-password-input"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setSignedUpUserInfo({
                    ...signedUpUserInfo,
                    password: evt.target.value.replace(/\s/g, ''),
                  });
                }}
                defaultValue={
                  signedUpUserInfo && signedUpUserInfo.password
                    ? signedUpUserInfo.password
                    : ''
                }
                icon={SAIcons.eye}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
            )}
            {error && (
              <SAInput
                data-private
                error
                id="sign-up-password-input-error"
                test-attr="sign-up-password-input-error"
                label="Password does not match requirements"
                type="password"
                fullWidth
                onClick={() => {
                  setError(false);
                }}
                defaultValue={
                  signedUpUserInfo && signedUpUserInfo.password
                    ? signedUpUserInfo.password
                    : ''
                }
              />
            )}
          </InputWrap>
          {passwordsDoNotMatch ? (
            <InputWrap>
              <SAInput
                data-private
                id="sign-up-password-input-confirm-pass-error"
                test-attr="sign-up-password-input-confirm-pass-error"
                label="Passwords must match"
                type="password"
                value={confirmedPassword}
                fullWidth
                error
                onClick={() => {
                  setPasswordsDoNotMatch(false);
                }}
              />
            </InputWrap>
          ) : (
            <InputWrap>
              <SAInput
                data-private
                id="sign-up-password-input-confirm-pass"
                test-attr="sign-up-password-input-confirm-pass"
                label="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onKeyDown={(evt) => {
                  if (evt.code === 'Space') evt.preventDefault();
                }}
                onChange={(evt) => {
                  setConfirmedPassword(evt.target.value.replace(/\s/g, ''));
                }}
                icon={SAIcons.eye}
                onClickIcon={() => setShowPassword(!showPassword)}
              />
            </InputWrap>
          )}
        </InputsHolder>
        <TextHolder>
          <SAText
            text="Password must be at least 12 characters, not include personal/account information or spaces, and include all 4 of the following characteristics:"
            type="small"
            alignment="center"
          />
          {!passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasCapital && (
            <GreenWrapper>
              <SAText
                text="1) Upper case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasLowercase && (
            <GreenWrapper>
              <SAText
                text="2) Lower case letter"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasNumber && (
            <GreenWrapper>
              <SAText text="3) Number" type="small" alignment="center" />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasNumber && (
            <GreenWrapper>
              <SAText
                text="3) Number"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {!passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
              />
              <CheckWrapper>
                <ClearAlert />
              </CheckWrapper>
            </GreenWrapper>
          )}
          {passwordHasSpecialChar && (
            <GreenWrapper>
              <SAText
                text="4) Special character"
                type="small"
                alignment="center"
                colorVariant="secondary-background"
              />
              <CheckWrapper>
                <GreenCheck />
              </CheckWrapper>
            </GreenWrapper>
          )}
        </TextHolder>
        <ButtonHolder>
          {!loading && (
            <SAButton
              test-attr="continue-set-password-screen"
              onClick={() => {
                if (confirmedPassword !== signedUpUserInfo?.password) {
                  setPasswordsDoNotMatch(true);
                } else checkUsernameAndPassword();
              }}
              label="CONTINUE"
              variant="primary-large"
              fullWidth
            />
          )}
          {loading && (
            <SAButton label="CONTINUE" variant="primary-large" fullWidth>
              <SpinnerLight />
            </SAButton>
          )}
        </ButtonHolder>
      </Card>
    </>
  );
};

export default SetPasswordModal;
