/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PopoverModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.small}px) {
          width: 500px;
      }
      padding: 25px;
      width: 100vw;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
    `;
  }}
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(4, 30, 65, 0.6);
`;

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        padding: ${theme.size.spacer.large};
        margin: auto auto 20px auto;
      }
      margin: auto auto 50px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      height: 500px;
      width: 725px;
      text-align: center;
      padding: 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
   `;
  }}
`;

export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
          height: 65vh;
          width: 100vw;
          padding: 20px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #E8EBF0;
        box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
        border-radius: 4px;
        height: 500px;
        width: 725px;
        padding: 50px 50px ${theme?.size?.spacer?.large} 50px;
        background-color: ${theme?.color.primary.foreground};
        border-radius: ${theme?.size.radius.small};
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        box-sizing: border-box;
     `;
  }}
`;

export const InnerTextHolder = styled.div`
  margin-top: 20px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin-top: 0px;
  }
`;

export const ContactOptionsHolder = styled.div`
  margin: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 40px 10px 10px 10px;
  }
`;

export const ContactButton = styled.div`
  background: #0173c6;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: ${customBase.global.breakpoints.xsmall}px) {
    font-size: 20px;
  }
`;

export const VerificationCodeInputHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 100%;
    margin: auto;
    padding-bottom: ${theme.size.spacer.small};
`}
`;

export const ButtonHolder = styled.div`
  width: 100%;
  padding: 0px 100px 0px 100px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;

export const TimerHolder = styled.div`
  margin-top: 25px;
`;

export const TextHolder = styled.div`
  margin-top: 25px;
`;

export const BottomTextHolder = styled.div`
  margin-top: 12px;
`;

export const InputHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px 10px;
`;

export const ErrorInputHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 10px 0px;
  @media screen and (min-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px 0px 0px 0px;
  }
`;

export const Input = styled.input`
  width: 100%;
  max-width: 85px;
  height: 85px;
  background: #e3e9f1;
  border: 1px solid rgba(1, 83, 142, 0.2);
  border-radius: 5px;
  font-size: 42px;
  text-align: center;
  margin: 0px 5px 0px 5px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    height: 60px;
  }
`;

export const ErrorInput = styled.input`
  width: 100%;
  max-width: 85px;
  height: 85px;
  background: #e3e9f1;
  border: 1px solid rgba(178, 18, 43, 0.8);
  border-radius: 5px;
  font-size: 42px;
  text-align: center;
  margin: 0px 5px 0px 5px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    height: 60px;
  }
`;

export const CloseIcon = styled.div`
  margin: 7.5px 0px 7.5px 0px;
`;

export const IconHolder = styled.div`
  padding: 18px 10px 10px 10px;
`;

export const ContactButtonTextHolder = styled.div`
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PhoneLink = styled.a`
  color: inherit;
  text-decoration: inherit;
`;
