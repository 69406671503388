import axios from 'axios';
import getConfig, { Env } from '../aws_exports'

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

export const retrieveRegistration = async (guid: string, env: string) => {
  const targetEndpoint = `${awsExports.registration.registrationEndpoint}?guid=${guid}&env=${env}`;
  const responseData = await axios({
    method: 'GET',
    url: targetEndpoint,
    maxRedirects: 0,
    headers: {
      'X-Api-Key': `${awsExports.registration.apiKey}`,
    },
  });
  return responseData?.data;
};
export default retrieveRegistration;