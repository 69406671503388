import React from 'react';
import { SAText } from '@saux/design-system-react';
import { ReactComponent as WhitePhoneIcon } from '../../../assets/images/WhitePhone.svg';
import {
  PopoverModal,
  InnerTextHolder,
  ContactButton,
  ContactOptionsHolder,
  IconHolder,
  ContactButtonTextHolder,
  PhoneLink,
} from '../PostVerifiedModalsStyles';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

export const NotVerifiedModal = () => {
  return (
    <>
      <PopoverModal>
        <InnerTextHolder>
          <SAText
            text="We apologize but we are unable to verify your account at this time. Please contact customer support."
            type="heading-3"
            alignment="center"
          />
        </InnerTextHolder>
        <ContactOptionsHolder>
          <ContactButton>
            <IconHolder>
              <WhitePhoneIcon />
            </IconHolder>
            <ContactButtonTextHolder>
              <PhoneLink
                href="tel:+18337243577"
                test-attr="phone-link-unverified"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: `Registration-NotVerifiedPhoneNumberButton-click`,
                    event_action: 'Button Click',
                    event_category: 'Registration',
                    event_label:
                      'Registration Not Verified Phone Number Button Click',
                    event_category_snowplow: 'Snowplow Registration',
                  });
                }}
              >
                1 (833) 724-3577
              </PhoneLink>
            </ContactButtonTextHolder>
          </ContactButton>
        </ContactOptionsHolder>
      </PopoverModal>
    </>
  );
};

export default NotVerifiedModal;
