import React from 'react';
import { SAText } from '@saux/design-system-react';
import { ReactComponent as SpinnerLoading } from '../../assets/images/Loading.svg';
import { TEXT_LOADING } from './RegistrationUtils';
import { Card } from './CommonViewStyles'

export const LoadingSpinner: any = () => {
  return (
    <>
      <Card>
        <SAText text={`${TEXT_LOADING}`}
        type='heading-3'
        alignment='center'
        colorVariant='primary'
        />
        <SpinnerLoading />
      </Card>
    </>
  );
};
export default LoadingSpinner;