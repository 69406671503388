import React, { useState } from 'react';
import styled from 'styled-components';
import { SAInput, SATooltip, SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';
import * as CONSTANTS from './RegistrationPInfoText';
import customBase from '../../util/global.theme';
import { IPersonalInformation } from '../../data/RegistrationData';

interface RegistrationPhoneDOBProps {
  registerUser: IPersonalInformation;
  businessType: string,
  onChangeDOBPhone: any,
  updateValidity: Function
};
const regexKeyDown = new RegExp('[\\d]', '');
const specialCharacters = [37, 39, 8, 46];
const regexPhoneGroups = /(\d{3})(\d{3})(\d{4})/g;
const regexCleanPhone =  /\D/g;
const DOBorMess = styled.div`
  width: 25%;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
  }
`;
const PhoneMessage = styled(DOBorMess)`
  display: inline-flex;
  align-items: center;
`;
const preventInvalidCharacters = (evt: any)=> {
  if (!specialCharacters.includes(evt.keyCode) && !evt.key.match(regexKeyDown)) evt.preventDefault();
};
const applyMaskPhone = (inputText: string) => {
  const onlyNumber = inputText.replaceAll(regexCleanPhone, '');
  const tmpArray = regexPhoneGroups.exec(onlyNumber);
  let returnField = inputText;
  if (onlyNumber?.length < 10) {
    returnField = onlyNumber;
  } else if (tmpArray) {
    returnField = `(${tmpArray[1]}) ${tmpArray[2]}-${tmpArray[3]}`;
  }
  return returnField;
};
const isValidPhone = (inputPhone: string) => {
  const onlyNumber = inputPhone.replaceAll(regexCleanPhone, '');
  return !(onlyNumber?.length < 10);

};

export const RegistrationPhoneDOB = ({registerUser, businessType = 'PL', onChangeDOBPhone, updateValidity}: RegistrationPhoneDOBProps) => {
  const [phoneConfirm, setPhoneConfirm] = useState('');
  const [validPhoneConfirm, setValidPhoneConfirm] = useState(true);
  const [validDOBConfirm, setValidDOBConfirm] = useState(true);
  const handleValueUpdate=(evt: any) => {
    if (evt) {
      evt.preventDefault();
      onChangeDOBPhone(evt.target.name, evt.target.value);
    }
  };
  const validityUpdate=(evt: any) => {
    console.log(evt.target.validity.valid);
    setValidDOBConfirm(evt.target.validity.valid);
    updateValidity(evt.target.validity.valid);
  };
  return (
    <>
    {
      businessType === 'CL' ?
      <>
        <DOBorMess>
          <SAInput
            id="sign-up-cl-phone-number-input"
            type="text"
            name="phone"
            hint={CONSTANTS.PINFO_PHONE}
            maxLength={10}
            value={phoneConfirm}
            onChange={(evt: any) => {
              setPhoneConfirm(applyMaskPhone(evt.target.value));
            }}
            onKeyDown={preventInvalidCharacters}
            onBlur={(evt) => {
              const validField = isValidPhone(evt.target.value);
              onChangeDOBPhone(evt.target.name, evt.target.value);
              setValidPhoneConfirm(validField);
              updateValidity(validField);
            }}
            error={!validPhoneConfirm}
            hideLabel
            fullWidth
          />
        </DOBorMess>
        <PhoneMessage>
          <SATooltip
           wrapperTestId='test-tooltip'
           testId='test-tooltip'
           content={CONSTANTS.PINFO_PHONE_HINT}
           icon="information"
           variant='dark'
           placement='left-end'
          >
            <SAIcon icon={SAIcons.information} size={SAIconSize.medium} colorVariant='primary'/>
          </SATooltip>
        </PhoneMessage>
      </> :
      <>
        <DOBorMess>
          <SAInput
            id="sign-up-pl-phone-number-input"
            type="text"
            value={registerUser.phone ?? ''}
            hint={`${registerUser.phoneType} Phone`}
            backgroundColor='rgba(204, 204, 204, 0.2)'
            hideLabel
            fullWidth
            disabled
          />
        </DOBorMess>
        <DOBorMess>
          <SAInput
            id="sign-up-pl-dob-input"
            name='dateOfBirth'
            type="date"
            min={CONSTANTS.PINFO_DOB_FIELD_CONFIG_MIN}
            hint={CONSTANTS.PINFO_DOB_HINT}
            value={registerUser.dateOfBirth ?? ''}
            onChange={handleValueUpdate}
            onBlur={validityUpdate}
            error={!validDOBConfirm}
            fullWidth
            hideLabel
          />
        </DOBorMess>
      </>
    }
    </>
  );
};
export default RegistrationPhoneDOB;