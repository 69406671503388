export const PERSONAL_INFO = 'Personal Information';
export const NAME_LABEL_CL = 'Primary Contact Name';
export const NAME_LABEL_PL = 'Insured Name';
export const NAME = 'Name';
export const PINFO_FIRST_NAME = 'First Name';
export const PINFO_MIDDLE_NAME = 'Middle Name/Initial';
export const PINFO_LAST_NAME = 'Last Name';
export const PINFO_EMAIL = 'Email Address';
export const PINFO_PHONE = " Phone Number";
export const PINFO_PHONE_HINT = "Enter phone number given to Agent for Primary Contact";
export const PINFO_DOB_HINT = "Date of Birth";

export const PINFO_DOB_FIELD_CONFIG_MIN = "1900-02-01";