/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import axios from 'axios';
import { MoreInfoNeededModalCL, UserInfo } from './MoreInfoNeededModalCL';
import getConfig, { Env } from '../../aws_exports';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface MoreInfoNeededModalCLDataContainerProps {
  setMoreInfoNeededCL: Function;
  setVerificationView: Function;
  setNotVerified: Function;
  setSecondEmailCheckView: Function;
}

const MoreInfoNeededModalCLDataContainer = ({
  setMoreInfoNeededCL,
  setVerificationView,
  setNotVerified,
  setSecondEmailCheckView,
}: MoreInfoNeededModalCLDataContainerProps) => {
  const [primaryContactFirstNameError, setPrimaryContactFirstNameError] =
    useState<boolean>(false);
  const [primaryContactLastNameError, setPrimaryContactLastNameError] =
    useState<boolean>(false);
  const [primaryContactPhoneError, setPrimaryContactPhoneError] =
    useState<boolean>(false);
  const [primaryContactZipCodeError, setPrimaryContactZipCodeError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const verifyEnteredInfo = async () => {
    if (!userInfo) {
      setPrimaryContactFirstNameError(true);
      setPrimaryContactLastNameError(true);
      setPrimaryContactPhoneError(true);
      setPrimaryContactZipCodeError(true);
    } else if (userInfo && !userInfo.primaryContactFirstName) {
      setPrimaryContactFirstNameError(true);
    } else if (userInfo && !userInfo.primaryContactLastName) {
      setPrimaryContactLastNameError(true);
    } else if (userInfo && !userInfo.primaryContactPhone) {
      setPrimaryContactPhoneError(true);
    } else if (userInfo && !userInfo.primaryContactZipCode) {
      setPrimaryContactZipCodeError(true);
    } else {
      setLoading(true);
      if (userInfo) {
        await axios({
          method: 'post',
          url: awsExports.gateway_endpoints.elasticSearchCL,
          data: {
            primary_contact_first_name: userInfo.primaryContactFirstName,
            primary_contact_last_name: userInfo.primaryContactLastName,
            primary_contact_phone_number: userInfo.primaryContactPhone?.replace(
              /[^0-9]/g,
              '',
            ),
            primary_contact_zip_code: userInfo.primaryContactZipCode,
          },
        })
          .then(async (res) => {
            res.data = JSON.parse(atob(res.data));
            const postData = {
              key1: [res.data],
              accountType: 'Business',
            };
            if (res.data) {
              await axios({
                method: 'post',
                url: awsExports.gateway_endpoints.getLoginFromAccNum,
                data: postData,
              })
                .then((response) => {
                  if (response.data) {
                    response.data = JSON.parse(atob(response.data));
                    if (
                      response.data.alreadyActiveInEID &&
                      response.data.associatedUserName &&
                      response.data.associatedUserName !== []
                    ) {
                      sessionStorage.setItem(
                        'registeredAccountsWithUserName',
                        JSON.stringify({
                          associatedUserName: response.data.associatedUserName,
                          accountNumbers: response.data.accountNumbers ?? [],
                        }),
                      );
                      setLoading(false);
                      setMoreInfoNeededCL(false);
                      setSecondEmailCheckView(true);
                    } else if (
                      response.data.userAttributes &&
                      response.data.userAttributes.userAttributesFromDynamo &&
                      response.data.userAttributes
                        .userAttributesFromDynamo[0] &&
                      response.data.userAttributes
                        .userAttributesFromDynamo[0] !== {}
                    ) {
                      sessionStorage.setItem(
                        'returnedGivenName',
                        response.data.userAttributes.userAttributesFromDynamo[0]
                          .first_name ?? '-',
                      );
                      sessionStorage.setItem(
                        'returnedAccountAttributesCollection',
                        JSON.stringify(
                          response.data.userAttributes.userAttributesFromDynamo,
                        ) ?? '-',
                      );
                      setLoading(false);
                      setMoreInfoNeededCL(false);
                      setVerificationView(true);
                    } else {
                      setLoading(false);
                      setMoreInfoNeededCL(false);
                      setNotVerified(true);
                    }
                  } else {
                    setLoading(false);
                    setMoreInfoNeededCL(false);
                    setNotVerified(true);
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                  setMoreInfoNeededCL(false);
                  setNotVerified(true);
                });
            }
          })
          .catch((err) => {
            console.log('ElasticSearch Function Call Error: ', err);
            setLoading(false);
            setMoreInfoNeededCL(false);
            setNotVerified(true);
          });
      }
    }
  };

  return (
    <MoreInfoNeededModalCL
      verifyEnteredInfo={verifyEnteredInfo}
      userInfo={userInfo}
      setUserInfo={setUserInfo}
      primaryContactFirstNameError={primaryContactFirstNameError}
      setPrimaryContactFirstNameError={setPrimaryContactFirstNameError}
      primaryContactLastNameError={primaryContactLastNameError}
      setPrimaryContactLastNameError={setPrimaryContactLastNameError}
      primaryContactPhoneError={primaryContactPhoneError}
      setPrimaryContactPhoneError={setPrimaryContactPhoneError}
      primaryContactZipCodeError={primaryContactZipCodeError}
      setPrimaryContactZipCodeError={setPrimaryContactZipCodeError}
      loading={loading}
    />
  );
};

export default MoreInfoNeededModalCLDataContainer;
