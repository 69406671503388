/* eslint-disable */
import React from 'react';
import { SAIcons } from '@saux/design-system-react';
import styled from 'styled-components';
import { SAInputContainer } from '../input/SAInput';
import { SAFormElementProps } from '../input/variants';

type SASelectItem = {
  label: string;
  value: string;
};

interface SASelectProps extends SAFormElementProps {
  defaultValue?: string;
  options: SASelectItem[];
  [x: string]: any;
}

const StyledInputContainer = styled(SAInputContainer)`
  select {
    appearance: none;
  }
`;

export const SASelect: React.FunctionComponent<SASelectProps> =
  React.forwardRef<HTMLSelectElement, SASelectProps>(
    (
      {
        options,
        defaultValue,
        id,
        label,
        hint,
        className,
        error,
        fullWidth,
        ...additionalProps
      },
      ref,
    ) => {
      return (
        <StyledInputContainer
          id={id}
          label={label}
          hint={hint}
          error={error}
          className={`saSelectWrapper ${className || ''}`}
          icon={SAIcons.chevronDownBold}
          fullWidth={fullWidth}
        >
          <select defaultValue={defaultValue} ref={ref} {...additionalProps}>
            {options.map((item) => {
              return (
                <option key={`${item.label}${item.value}`} label={item.label}>
                  {item.value}
                </option>
              );
            })}
          </select>
        </StyledInputContainer>
      );
    },
  );
SASelect.defaultProps = {
  defaultValue: undefined,
};
