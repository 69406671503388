import React from 'react';
import { SAText } from '@saux/design-system-react';
import { Card } from './CommonViewStyles';

export interface ExceptionProps {
  headerException: string;
  messageException: string;
};

export const GenericExceptionView: any = ( {headerException, messageException}: ExceptionProps) => {
  return (
    <>
      <Card>
        <SAText text={`${headerException}`}
        type='heading-2'
        alignment='center'
        colorVariant='primary'
        />
        <SAText text={`${messageException}`}
        type='heading-4'
        alignment='center'
        colorVariant='primary'
        />
      </Card>
    </>
  );
};
export default GenericExceptionView;