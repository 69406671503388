/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import axios from 'axios';
import { MoreInfoNeededModal, UserInfo } from './MoreInfoNeededModal';
import getConfig, { Env } from '../../aws_exports';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface MoreInfoNeededModalDataContainerProps {
  setMoreInfoNeeded: Function;
  setVerificationView: Function;
  setNotVerified: Function;
  setSecondEmailCheckView: Function;
}

const MoreInfoNeededModalDataContainer = ({
  setMoreInfoNeeded,
  setVerificationView,
  setNotVerified,
  setSecondEmailCheckView,
}: MoreInfoNeededModalDataContainerProps) => {
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [dobError, setDobError] = useState<boolean>(false);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  const verifyEnteredInfo = async () => {
    if (!userInfo) {
      setFirstNameError(true);
      setLastNameError(true);
      setDobError(true);
      setZipCodeError(true);
    } else if (userInfo && !userInfo.firstName) {
      setFirstNameError(true);
    } else if (userInfo && !userInfo.lastName) {
      setLastNameError(true);
    } else if (userInfo && !userInfo.dateOfBirth) {
      setDobError(true);
    } else if (userInfo && !userInfo.zipCode) {
      setZipCodeError(true);
    } else {
      setLoading(true);
      if (userInfo) {
        await axios({
          method: 'post',
          url: awsExports.gateway_endpoints.elasticSearch,
          data: {
            firstname: userInfo.firstName,
            lastname: userInfo.lastName,
            dateofbirth: userInfo.dateOfBirth,
            zipState: userInfo.zipCode,
          },
        })
          .then(async (res) => {
            res.data = JSON.parse(atob(res.data));
            const postData = {
              key1: [res.data],
            };
            if (res.data) {
              await axios({
                method: 'post',
                url: awsExports.gateway_endpoints.getLoginFromAccNum,
                data: postData,
              })
                .then((response) => {
                  if (response.data) {
                    response.data = JSON.parse(atob(response.data));
                    if (
                      response.data.alreadyActiveInEID &&
                      response.data.associatedUserName &&
                      response.data.associatedUserName !== []
                    ) {
                      sessionStorage.setItem(
                        'registeredAccountsWithUserName',
                        JSON.stringify({
                          associatedUserName: response.data.associatedUserName,
                          accountNumbers: response.data.accountNumbers ?? [],
                        }),
                      );
                      setLoading(false);
                      setMoreInfoNeeded(false);
                      setSecondEmailCheckView(true);
                    } else if (
                      response.data.userAttributes &&
                      response.data.userAttributes.userAttributesFromDynamo &&
                      response.data.userAttributes
                        .userAttributesFromDynamo[0] &&
                      response.data.userAttributes
                        .userAttributesFromDynamo[0] !== {}
                    ) {
                      sessionStorage.setItem(
                        'returnedGivenName',
                        response.data.userAttributes.userAttributesFromDynamo[0]
                          .first_name ?? '-',
                      );
                      sessionStorage.setItem(
                        'returnedAccountAttributesCollection',
                        JSON.stringify(
                          response.data.userAttributes.userAttributesFromDynamo,
                        ) ?? '-',
                      );
                      setLoading(false);
                      setMoreInfoNeeded(false);
                      setVerificationView(true);
                    } else {
                      setLoading(false);
                      setMoreInfoNeeded(false);
                      setNotVerified(true);
                    }
                  } else {
                    setLoading(false);
                    setMoreInfoNeeded(false);
                    setNotVerified(true);
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                  setMoreInfoNeeded(false);
                  setNotVerified(true);
                });
            }
          })
          .catch((err) => {
            console.log('ElasticSearch Function Call Error: ', err);
            setLoading(false);
            setMoreInfoNeeded(false);
            setNotVerified(true);
          });
      }
    }
  };

  return (
    <MoreInfoNeededModal
      verifyEnteredInfo={verifyEnteredInfo}
      userInfo={userInfo}
      setUserInfo={setUserInfo}
      firstNameError={firstNameError}
      setFirstNameError={setFirstNameError}
      lastNameError={lastNameError}
      setLastNameError={setLastNameError}
      dobError={dobError}
      setDobError={setDobError}
      zipCodeError={zipCodeError}
      setZipCodeError={setZipCodeError}
      loading={loading}
    />
  );
};

export default MoreInfoNeededModalDataContainer;
