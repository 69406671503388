import React, { useEffect, useState } from 'react';
import { SATableColumn } from '@saux/design-system-react';
import { AccountAlreadyRegistered } from './AccountAlreadyRegistered';
import { formatTitleCase } from '../../util';

interface FoundUserNameProps {
  associatedUserName: AssociatedSameEmailUsersProps[];
}

interface AssociatedSameEmailUsersProps {
  Email?: string;
  Login?: string;
}

export const mergeByAccountNumberAndSort = (
  accountArray: any[],
  policyArray: any[],
) => {
  if (accountArray.length !== 0 && policyArray.length !== 0) {
    return accountArray.map((itm) => ({
      ...policyArray.find(
        (item) => item.account_number === itm.account_number && item,
      ),
      ...itm,
    }));
  }
  return accountArray;
};

export const maskFoundUsername = (
  foundUserName: string | undefined,
): string => {
  let masked: string = '-';
  if (foundUserName) {
    if (foundUserName.includes('@')) {
      const first = foundUserName.substring(0, 1);
      const last = foundUserName.substring(
        foundUserName.indexOf('@') - 2,
        foundUserName.length,
      );
      masked = `${first}****${last}`;
    } else {
      const first = foundUserName.substring(0, 1);
      const last = foundUserName.substring(foundUserName.length - 3);
      masked = `${first}****${last}`;
    }
  }
  return masked;
};

export const organizeTableData = (
  mergedData: any,
  foundUserInfo: FoundUserNameProps | string | undefined,
): any[][] => {
  const rowInfo: any[][] = [];

  if (mergedData.length === 0) {
    const maskedUsername: string =
      typeof foundUserInfo === 'object' &&
      foundUserInfo.associatedUserName[0] &&
      foundUserInfo.associatedUserName[0].Login
        ? maskFoundUsername(foundUserInfo.associatedUserName[0].Login)
        : 'Could Not Find Username';
    rowInfo.push(['Insured Account', 'Insured Policy', `${maskedUsername}`]);
  } else {
    mergedData.forEach((obj: any, index: number) => {
      const accNumWording: string = `ending in xxx${obj.account_number.substr(
        obj.account_number.length - 3,
      )}`;

      const policyWording: string =
        formatTitleCase(obj.product_code) ?? 'Insured Policy';
      const maskedUsername: string =
        typeof foundUserInfo === 'object' &&
        foundUserInfo.associatedUserName[index] &&
        foundUserInfo.associatedUserName[index].Login
          ? maskFoundUsername(foundUserInfo.associatedUserName[index].Login)
          : 'Could Not Find Username';

      rowInfo.push([
        `${accNumWording}`,
        `${policyWording}`,
        `${maskedUsername}`,
      ]);
    });
  }
  return rowInfo;
};

const tableColumns: SATableColumn[] = [
  {
    align: 'left',
    name: 'Account Number',
  },
  {
    align: 'left',
    name: 'Policy Type',
  },
  {
    align: 'left',
    name: 'Username',
  },
];

const AccountAlreadyRegisteredDataContainer = () => {
  const [foundUserName, setFoundUser] = useState<FoundUserNameProps | string>(
    '',
  );
  const [foundAccountData, setFoundAccountData] = useState<any[] | undefined>(
    undefined,
  );
  const [foundPolicyData, setFoundPolicyData] = useState<any[] | undefined>(
    undefined,
  );
  const [tableData, setTableData] = useState<any[][]>([]);

  useEffect(() => {
    setFoundUser(
      sessionStorage.getItem('alreadyRegisteredAccounts')
        ? JSON.parse(
            sessionStorage.getItem('alreadyRegisteredAccounts') ?? '""',
          )
        : '',
    );
  }, []);

  useEffect(() => {
    setFoundAccountData(
      JSON.parse(
        sessionStorage.getItem('returnedAccountAttributesCollection') ?? '[]',
      ),
    );
    setFoundPolicyData(
      JSON.parse(
        sessionStorage.getItem('returnedPolicyAttributesCollection') ?? '[]',
      ),
    );
  }, [foundUserName]);

  useEffect(() => {
    if (foundAccountData && foundPolicyData) {
      const mergedData = mergeByAccountNumberAndSort(
        foundAccountData,
        foundPolicyData,
      );

      setTableData(organizeTableData(mergedData, foundUserName));
    }
  }, [foundAccountData, foundPolicyData, foundUserName]);

  return (
    <AccountAlreadyRegistered
      tableColumns={tableColumns}
      tableData={tableData}
    />
  );
};

export default AccountAlreadyRegisteredDataContainer;
