/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const LeftInput = styled.input`
  margin-right: 25px;
  height: 15px;
  width: 15px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 5px 10px 0px 0px;
  }
`;

export const EmptyInput = styled.div`
  margin-left: 40px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 0px;
  }
`;

export const Card = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
        width: 100vw;
        padding: 25px;
      }
      margin: auto auto 50px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #E8EBF0;
      box-shadow: 0px ${theme.size.spacer.xxs} ${theme.size.spacer.xs} rgba(1, 83, 142, 0.2);
      width: 725px;
      text-align: center;
      padding: 50px;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      box-sizing: border-box;
   `;
  }}
`;

export const ButtonHolder = styled.div`
  width: 100%;
  padding: 0px 100px 0px 100px;
  margin: 50px 0px 0px 0px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    padding: 0px;
  }
`;

export const HeadingTextHolder = styled.div`
  padding-bottom: 50px;
`;

export const LowerTextHolder = styled.div`
  padding-top: 40px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-height: 190px;
  overflow: scroll;
`;
