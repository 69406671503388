/* eslint-disable */
import React from 'react';
import { SAInputButtonProps } from './input.variant.shared';

export function InputButtonStyles(props: SAInputButtonProps) {
  const { theme, variant } = props;

  // TODO: Add button color pairs to theme and tokens. Current handling is not intuitive.
  // This will be cleaned up once the theme & tokens have been updated.
  let themeColorPair = theme?.color.primary; // Use for secondary buttons... I know...  :-)

  if (variant?.includes('button-primary')) {
    themeColorPair = theme?.color.secondary;
  }

  if (variant?.includes('button-link')) {
    themeColorPair = theme?.color.primary;
  }

  let backgroundColor = variant?.includes('outline')
    ? 'transparent'
    : themeColorPair?.background;
  let border = variant?.includes('outline')
    ? `1px solid ${themeColorPair?.background}`
    : 'transparent';
  let color = variant?.includes('outline')
    ? themeColorPair?.background
    : themeColorPair?.foreground;

  if (variant?.includes('link')) {
    backgroundColor = 'transparent';
    border = 'transparent';
    color = themeColorPair?.background;
  }

  const fontSize = variant?.includes('small')
    ? theme?.font.primary.size.smallTEMP
    : theme?.font.primary.size.mediumTEMP;

  const padding = variant?.includes('small')
    ? `${theme?.size.spacer.xs} ${theme?.size.spacer.medium}`
    : `${theme?.size.spacer.medium} ${theme?.size.spacer.large}`;

  return `
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      background-color: ${backgroundColor};
      border: ${border};
      border-radius: ${theme?.size.radius.small};
      color: ${color};
      font-family: ${theme?.font.primary.family};
      font-size: ${fontSize};
      font-weight: ${theme?.font.primary.weight.bold};
      margin: 0;
      padding: ${padding};
      text-decoration: none;
      text-transform: uppercase;
    }
  `;
}
