/* eslint-disable */
import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PopoverModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
          width: 500px;
          height: 350px;
      }
      padding: 35px;
      width: 100vw;
      height: auto;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 1px solid #E8EBF0;
      box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
    `;
  }}
`;

export const InnerTextHolder = styled.div`
  margin-top: 20px;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin-top: 0px;
  }
`;

export const ContactOptionsHolder = styled.div`
  margin: 70px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 40px 10px 10px 10px;
  }
`;

export const LoadingHolder = styled.div`
  margin: 60px 40px 40px 40px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 30px 10px 10px 10px;
  }
`;

export const ContactButton = styled.div`
  background: #0173c6;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: ${customBase.global.breakpoints.xsmall}px) {
    font-size: 20px;
  }
`;

export const IconHolder = styled.div`
  padding: 18px 10px 10px 10px;
`;

export const ContactButtonTextHolder = styled.div`
  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const PhoneLink = styled.a`
  color: inherit;
  text-decoration: inherit;
`;
