import React, { useState, useRef, useEffect } from 'react';
import { SAAlert, SAButton, SAText } from '@saux/design-system-react';
import {
  TextHolder,
  Card,
} from '../signUpModal/SignUpModalStyles';
import { UpperSpacedElement, LowerSpacedElement } from '../../views/registrationView/CommonViewStyles';
import { RegistrationUsername, RegistrationQuestions, RegistrationPInfo } from '..';
import { ICustomerRegistration, ILoginInformation, IPersonalInformation, IRequestRegistration, IScreenValidation } from '../../data/RegistrationData';
import {
  ERROR_ASSIST
} from '../../views/registrationView/RegistrationUtils';

interface RegistrationFormContainerProps {
  registerUser: ICustomerRegistration,
  handleSubmitRegistration: any,
  validationMessages: IScreenValidation
}

export const TEXT_WELCOME = 'Welcome to State Auto';
export const TEXT_CONFIRM_INFO =
  'Lastly, confirm your personal information before finishing the registration.';
interface SelectedOptionSet {
    questionSetIndex: number,
    valueText: string,
    answerText: string
  }

export const RegistrationFormContainer = ({registerUser, handleSubmitRegistration, validationMessages}: RegistrationFormContainerProps) => {
  const [personalInformation, setPersonalInfo] = useState<IPersonalInformation>(registerUser.personalInformation);
  const [loginInformation, setLoginInformation] = useState<ILoginInformation>({username:"", password:""});
  const businessType = useRef<string>(registerUser.businessType);
  /**
   * State Variables for valid component
   */
  const [isLoginComponentValid, setLoginComponentValid] = useState<boolean>(false);
  const [isPersonalInfoComponentValid, setPersonalInfoComponentValid] = useState<boolean>(false);
  const handleCustomerInformationUpdate =
  (field: string, value:string) => {
    setPersonalInfo(
      {
        ...personalInformation,
        [field]:value
      }
    );
  };
  const handleLoginInformationUpdate =
  (field: string, value:string) => {
    setLoginInformation(
      {
        ...loginInformation,
        [field]:value
      }
    );
  };
  /**
   * State Variables and event handlers for Questions
   */
  const [selectedQuestions, setSelectedQuestions]=useState<Array<SelectedOptionSet>>([]);
  const handleSelectOption = (newValue: string, indexValue: number, newAnswer: string)=>{
    const nextSelected=[...selectedQuestions];
    const findSelect=nextSelected.find((itm) => { return itm.questionSetIndex === indexValue});
    if (findSelect) {
      findSelect.valueText = newValue;
    } else {
      nextSelected.push({ questionSetIndex:indexValue, valueText:newValue, answerText:newAnswer });
    }
    setSelectedQuestions(nextSelected);
  };
  const handleAnswerUpdate=(answerValue: string, answerIndex: number)=>{
    const nextAnswered=[...selectedQuestions];
    const findSelect=nextAnswered.find((itm) => { return Number(itm.questionSetIndex) === Number(answerIndex)});
    if (findSelect) {
      findSelect.answerText = answerValue;
      setSelectedQuestions(nextAnswered);
    }
  };
  const validateSelectedQuestions=()=>{
    const hasAnswerInvalid = selectedQuestions.findIndex((itmQues)=>{
      return itmQues.answerText?.length<4;
    });
    return selectedQuestions.length >= 3 && hasAnswerInvalid < 0;
  };
  const buildQuestionSubmitFormat = () => {
    return selectedQuestions.map((itm) => {
      return {
        questionText: itm.valueText,
        answer: btoa(itm.answerText)
      }
    });
  };
  const buildRegistrationSubmitData = () => {
    const rqRegistration: IRequestRegistration = {
      accountnumber: registerUser.account,
      dateofbirth: personalInformation.dateOfBirth ?? '',
      displayname: `${personalInformation.firstName} ${personalInformation.firstName}`,
      email: personalInformation.email,
      familyName: personalInformation.lastName,
      firstname: personalInformation.firstName,
      givenName: personalInformation.firstName,
      lastname: personalInformation.lastName,
      lobIndicator: registerUser.businessType,
      mobilenumber: personalInformation.phone ?? '',
      phone: personalInformation.phone ?? '',
      secondaryPhoneNumber: personalInformation.phone ?? '',
      passwd: btoa(loginInformation.password),
      username: loginInformation.username,
      guid: registerUser.guid,
      kbas: buildQuestionSubmitFormat()
    };
    return rqRegistration;
  };
  return (
    <>
      <SAText
        text={`${TEXT_WELCOME}${
          registerUser ? `, ${registerUser?.personalInformation?.firstName}` : ''
        }!`}
        type="heading-1"
        alignment="center"
        casing='capitalize'
      />
      <TextHolder>
        <SAText
          text={TEXT_CONFIRM_INFO}
          type="standard"
          weight="bold"
          alignment="center"
        />
      </TextHolder>
      <Card>
        {
          validationMessages?.error &&
          <LowerSpacedElement>
            <SAAlert severity='error'>
              {validationMessages.error.join('<br/>')}<br/>{ERROR_ASSIST}
            </SAAlert>
          </LowerSpacedElement>
        }
        <RegistrationPInfo
          registerUser={personalInformation}
          businessType={businessType.current}
          handleInformationUpdate={handleCustomerInformationUpdate}
          handleUpdateValidity={setPersonalInfoComponentValid}
        />
        <RegistrationUsername
          defaultUsername={registerUser.loginInformation.username}
          handleUpdateValid={setLoginComponentValid}
          handleUpdateLogin={handleLoginInformationUpdate}
        />
        <RegistrationQuestions
          selectedQuestions={selectedQuestions}
          handleSelectOption={handleSelectOption}
          handleAnswerUpdate={handleAnswerUpdate}
        />
        <UpperSpacedElement>
          <SAButton
            label='Submit'
            variant='primary-large'
            disabled={!(validateSelectedQuestions() && isLoginComponentValid && isPersonalInfoComponentValid)}
            onClick={()=>{
              handleSubmitRegistration(buildRegistrationSubmitData());
            }}
          />
        </UpperSpacedElement>
      </Card>
    </>
  );
};