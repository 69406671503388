import React from 'react';
import { LocationProvider } from '@reach/router';
import { GlobalStyle, SAUX360Theme } from '@saux/design-system-react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';

ReactDOM.render(
  <ThemeProvider theme={SAUX360Theme}>
    <LocationProvider>
      <GlobalStyle />
      <App />
    </LocationProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
