/* eslint-disable no-unneeded-ternary */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Redirect, Router } from '@reach/router';
import { SAFooter, SAHeader } from '@saux/design-system-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import TagManager from 'react-gtm-module';
import getConfig, { Env } from './aws_exports';
import StyledInnerContentWrapper from './AppStyles';
import {
  AccountType,
  FirstEmailCheckModal,
  AccountAlreadyRegistered,
  VerificationCodeModal,
  MoreInfoNeededModal,
  SignUpModal,
  MultipleAccountsAssociated,
  MoreInfoNeededModalCL,
  VerificationCodeChoiceModal,
} from './components';
import NotVerifiedModal from './components/postVerificationModals/notVerifiedModal';
import LoadingModal from './components/postVerificationModals/loadingModal';
import { RegistrationView } from './views/registrationView/RegistrationView';

const awsExports = getConfig(process.env.REACT_APP_ENV as Env);

interface ModalViewsProps {
  accountType: MutableRefObject<string>;
  initialAccountType: boolean;
  setInitialAccountType: Function;
  initialEmailCheckView: boolean;
  setInitialEmailCheckView: Function;
  secondEmailCheckView: boolean;
  setSecondEmailCheckView: Function;
  multipleAccountsAssociatedView: boolean;
  setMultipleAccountsAssociatedView: Function;
  signUpView: boolean;
  setSignUpView: Function;
  verificationView: boolean;
  setVerificationView: Function;
  verificationChoiceView: boolean;
  setVerificationChoiceView: Function;
  moreInfoNeeded: boolean;
  setMoreInfoNeeded: Function;
  moreInfoNeededCL: boolean;
  setMoreInfoNeededCL: Function;
  notVerified: boolean;
  setNotVerified: Function;
  verifiedLoading: boolean;
  setVerifiedLoading: Function;
  inputtedEmailNotFound: boolean;
  setInputtedEmailNotFound: Function;
  showEmailAsDefaultUsername: boolean;
  setShowEmailAsDefaultUsername: Function;
  selectedVerificationMethod: MutableRefObject<string>;
}

export const ModalViews: any = ({
  accountType,
  initialAccountType,
  setInitialAccountType,
  initialEmailCheckView,
  setInitialEmailCheckView,
  secondEmailCheckView,
  setSecondEmailCheckView,
  multipleAccountsAssociatedView,
  setMultipleAccountsAssociatedView,
  signUpView,
  setSignUpView,
  verificationChoiceView,
  setVerificationChoiceView,
  verificationView,
  setVerificationView,
  moreInfoNeeded,
  setMoreInfoNeeded,
  moreInfoNeededCL,
  setMoreInfoNeededCL,
  notVerified,
  setNotVerified,
  verifiedLoading,
  setVerifiedLoading,
  inputtedEmailNotFound,
  setInputtedEmailNotFound,
  showEmailAsDefaultUsername,
  setShowEmailAsDefaultUsername,
  selectedVerificationMethod,
}: ModalViewsProps) => {
  if (initialAccountType) {
    return (
      <AccountType
        setInitialAccountType={setInitialAccountType}
        setInitialEmailCheckView={setInitialEmailCheckView}
        accountType={accountType}
      />
    );
  }
  if (initialEmailCheckView) {
    return (
      <FirstEmailCheckModal
        setInitialEmailCheckView={setInitialEmailCheckView}
        setSecondEmailCheckView={setSecondEmailCheckView}
        setMultipleAccountsAssociatedView={setMultipleAccountsAssociatedView}
        setMoreInfoNeeded={setMoreInfoNeeded}
        setMoreInfoNeededCL={setMoreInfoNeededCL}
        setVerificationView={setVerificationChoiceView}
        accountType={accountType.current}
      />
    );
  }
  if (secondEmailCheckView) {
    return <AccountAlreadyRegistered />;
  }
  if (multipleAccountsAssociatedView) {
    setShowEmailAsDefaultUsername(false);
    return (
      <MultipleAccountsAssociated
        setMultipleAccountsAssociatedView={setMultipleAccountsAssociatedView}
        setVerificationView={setVerificationChoiceView}
      />
    );
  }
  if (verificationChoiceView) {
    return (
      <VerificationCodeChoiceModal
        setVerificationView={setVerificationView}
        setVerificationChoiceView={setVerificationChoiceView}
        selectedVerificationMethod={selectedVerificationMethod}
      />
    );
  }
  if (verificationView) {
    return (
      <VerificationCodeModal
        setVerificationView={setVerificationView}
        setNotVerified={setNotVerified}
        setVerifiedLoading={setVerifiedLoading}
        setSignUpView={setSignUpView}
        selectedVerificationMethod={selectedVerificationMethod}
        verificationMethodValue={selectedVerificationMethod.current}
      />
    );
  }

  if (moreInfoNeeded) {
    setInputtedEmailNotFound(true);
    return (
      <MoreInfoNeededModal
        setMoreInfoNeeded={setMoreInfoNeeded}
        setVerificationView={setVerificationChoiceView}
        setNotVerified={setNotVerified}
        setSecondEmailCheckView={setSecondEmailCheckView}
      />
    );
  }
  if (moreInfoNeededCL) {
    setInputtedEmailNotFound(true);
    return (
      <MoreInfoNeededModalCL
        setMoreInfoNeededCL={setMoreInfoNeededCL}
        setVerificationView={setVerificationChoiceView}
        setNotVerified={setNotVerified}
        setSecondEmailCheckView={setSecondEmailCheckView}
      />
    );
  }
  if (notVerified) {
    return <NotVerifiedModal />;
  }
  if (verifiedLoading) {
    return (
      <LoadingModal message="Thank you for the verification. Please wait while we direct you to the final stage of registration." />
    );
  }
  if (signUpView) {
    return (
      <SignUpModal
        setNotVerified={setNotVerified}
        setSignUpView={setSignUpView}
        accountType={accountType.current}
        inputtedEmailNotFound={inputtedEmailNotFound}
        showEmailAsDefaultUsername={showEmailAsDefaultUsername}
      />
    );
  }
  return 'Welcome to State Auto';
};

export const resetSession = () => {
  sessionStorage.clear();
  return '';
};

const App = () => {
  const [initialAccountType, setInitialAccountType] = useState<boolean>(true);
  const [initialEmailCheckView, setInitialEmailCheckView] =
    useState<boolean>(false);
  const [secondEmailCheckView, setSecondEmailCheckView] =
    useState<boolean>(false);
  const [multipleAccountsAssociatedView, setMultipleAccountsAssociatedView] =
    useState<boolean>(false);
  const [signUpView, setSignUpView] = useState<boolean>(false);
  const [verificationChoiceView, setVerificationChoiceView] =
    useState<boolean>(false);
  const [verificationView, setVerificationView] = useState<boolean>(false);
  const [moreInfoNeeded, setMoreInfoNeeded] = useState<boolean>(false);
  const [moreInfoNeededCL, setMoreInfoNeededCL] = useState<boolean>(false);
  const [notVerified, setNotVerified] = useState<boolean>(false);
  const [verifiedLoading, setVerifiedLoading] = useState<boolean>(false);
  const [inputtedEmailNotFound, setInputtedEmailNotFound] =
    useState<boolean>(false);
  const [showEmailAsDefaultUsername, setShowEmailAsDefaultUsername] =
    useState<boolean>(true);
  const accountType = useRef<string>('Personal');
  const selectedVerificationMethod = useRef<string>('');

  const logRocketAppId = awsExports.logRocketAppID;
  const sdkServer = awsExports.logRocketSdkServer;
  const ingestServer = awsExports.logRocketIngestServer;

  LogRocket.init(logRocketAppId, {
    sdkServer,
    ingestServer,
  });

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-T669FQM',
    });
  }, []);

  setupLogRocketReact(LogRocket);

  useEffect(() => {
    window.addEventListener('beforeunload', resetSession);
    return () => {
      window.removeEventListener('beforeunload', resetSession);
    };
  }, []);

  return (
    <>
      <SAHeader />
      <StyledInnerContentWrapper>
        <Router>
          <Redirect from="/" to="/customer-registration" noThrow />
          <ModalViews
            path="/register"
            accountType={accountType}
            initialAccountType={initialAccountType}
            setInitialAccountType={setInitialAccountType}
            initialEmailCheckView={initialEmailCheckView}
            setInitialEmailCheckView={setInitialEmailCheckView}
            secondEmailCheckView={secondEmailCheckView}
            setSecondEmailCheckView={setSecondEmailCheckView}
            multipleAccountsAssociatedView={multipleAccountsAssociatedView}
            setMultipleAccountsAssociatedView={
              setMultipleAccountsAssociatedView
            }
            signUpView={signUpView}
            setSignUpView={setSignUpView}
            verificationChoiceView={verificationChoiceView}
            setVerificationChoiceView={setVerificationChoiceView}
            verificationView={verificationView}
            setVerificationView={setVerificationView}
            moreInfoNeeded={moreInfoNeeded}
            setMoreInfoNeeded={setMoreInfoNeeded}
            moreInfoNeededCL={moreInfoNeededCL}
            setMoreInfoNeededCL={setMoreInfoNeededCL}
            notVerified={notVerified}
            setNotVerified={setNotVerified}
            verifiedLoading={verifiedLoading}
            setVerifiedLoading={setVerifiedLoading}
            inputtedEmailNotFound={inputtedEmailNotFound}
            setInputtedEmailNotFound={setInputtedEmailNotFound}
            showEmailAsDefaultUsername={showEmailAsDefaultUsername}
            setShowEmailAsDefaultUsername={setShowEmailAsDefaultUsername}
            selectedVerificationMethod={selectedVerificationMethod}
          />
          <RegistrationView path="/customer-registration/:env/:guid" />
        </Router>
      </StyledInnerContentWrapper>
      <SAFooter />
    </>
  );
};

export default App;
